.bread-crumbs {
  display: flex;
  padding: 22px 0 0 0;
  flex: 0;
  nav {
    display: flex;
    span,
    a {
      position: relative;
      display: flex;
      font-family: TBCXRegular;
      font-size: 10px;
      color: rgba($color: #181a26, $alpha: 0.5);
      text-transform: capitalize;
      margin-right: 15px;
      white-space: nowrap;
      &::after {
        content: '>';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -10px;
      }
      &:last-child {
        margin-right: 0;
        &::after {
          content: none;
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  .bread-crumbs {
    display: none;
  }
}
