.modal-manager {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9;
  opacity: 0;
  padding: 40px 0;
  background-color: rgba($color: #181d26, $alpha: 0.9);
  .modal-manager-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 660px;
    button.modal-manager-close-btn {
      position: absolute;
      top: -34px;
      right: -52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
      border: none;
      border-radius: 100%;
      width: 34px;
      height: 34px;
      transition: background-color 0.2s ease;
      svg {
        display: block;
        width: 10px;
        height: 10px;
        path {
          fill: rgba($color: #181d26, $alpha: 0.9);
        }
      }
      &:hover {
        background-color: #ff5b3a;
      }
    }
    .modal-manager-view {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 30px;
      background-color: #ffffff;
      padding: 30px 20px;
    }
    .modal-manager-lvl {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
      ul {
        display: flex;
        align-items: center;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 70px;
          background-color: rgba($color: #181a26, $alpha: 0.3);
          border-radius: 100%;
          margin: 0 17px;
          img {
            display: flex;
            width: 50%;
          }
          &.current {
            width: 84px;
            height: 84px;
            background-color: #FD4100;
          }
        }
      }
    }
    .review-modal-view {
      padding: 44px 60px 50px 60px;
      p.caption {
        font-family: TBCXMedium;
        font-size: 36px;
        color: #181a26;
      }
      .review {
        display: flex;
        justify-content: space-between;
        padding: 76px 0 77px 0;
        .left {
          display: flex;
          align-items: center;
          svg,
          img {
            width: 46px;
            height: 46px;
            object-fit: cover;
            border-radius: 100%;
          }
          p {
            font-size: 13px;
            color: #181a26;
            padding-left: 20px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          ul {
            display: flex;
            li {
              margin-left: 8px;
              svg {
                width: 18px;
                height: 18px;
                path {
                  fill: #ffdab2;
                  stroke: #ffdab2;
                }
              }
              &.starred {
                svg {
                  path {
                    fill: #FD4100;
                    stroke: #FD4100;
                  }
                }
              }
            }
          }
        }
      }
      .form-container {
        display: flex;
        flex-direction: column;
        form {
          display: flex;
          flex-direction: column;
        }
        .field {
          display: flex;
          &:last-child {
            border-top: 1px solid #e3e5e9;
            margin-top: 10px;
          }
          textarea {
            width: 100%;
            font-size: 14px;
            line-height: 1.5;
            color: #181a26;
            border: none;
            resize: none;
            padding: 10px 0;
            &:focus {
              outline: none;
            }
            &::-webkit-input-placeholder {
              color: rgba($color: #181a26, $alpha: 0.5);
            }
            &::-moz-placeholder {
              color: rgba($color: #181a26, $alpha: 0.5);
            }
            &:-ms-input-placeholder {
              color: rgba($color: #181a26, $alpha: 0.5);
            }
            &:-moz-placeholder {
              color: rgba($color: #181a26, $alpha: 0.5);
            }
          }
          button {
            width: 100%;
            height: 80px;
            border-radius: 16px;
            border: none;
            font-family: TBCXMedium;
            font-size: 22px;
            color: #ffffff;
            margin-top: 90px;
            box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
            background-color: #FD4100;
            &:disabled {
              background-color: #e3e5e9;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 850px) {
  .modal-manager {
    .modal-manager-container {
      button.modal-manager-close-btn {
        top: 10px;
        right: 10px;
        width: 34px;
        height: 34px;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}
