.c-1 {
  fill: none;
  stroke: #fff;
  stroke-width: 24px;
}
.c-2 {
  fill: #f4f5f6;
}
.c-3 {
  fill-rule: evenodd;
  fill: none;
  stroke: #fff;
  stroke-width: 24px;
}
.b-1 {
  fill-rule: evenodd;
  fill: #f4f5f6;
}
image.an-img-1 {
  width: 57px;
}
image.an-img-2 {
  width: 57px;
}
image.an-img-3 {
  width: 54px;
}
image.an-img-4 {
  width: 60px;
}
image.an-img-5 {
  width: 57px;
}
image.an-img-6 {
  width: 51px;
}
image.an-traffic-light {
  width: 16px;
}
image.an-stop {
  width: 15px;
}
image.an-crosswalk {
  width: 19px;
}
