@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  .home-section .home-section-right .join-info {
    padding-left: get-vw(150px);
    padding-right: get-vw(100px);
  }
}

.driving-theory-section .theory-img img {
  max-width: 360px;
  width: 100%;
  border-radius: 16px;
}

@media only screen and (max-width: 1600px) {
  .home-section .home-section-title {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 78px;
  }

  .driving-theory-section {
    padding-top: 200px;
    padding-bottom: 240px;

    .theory-img {
      margin-left: 20px;
      box-shadow: none;
      overflow: visible;

      img {
        box-shadow: 0 10px 60px 0 rgba(121, 126, 168, 0.08);
      }
    }

    .theory-text {
      padding-right: 100px;
    }

    .theory-details {
      display: flex;
      padding-left: 40px;
      padding-top: 120px;
      padding-right: 100px;
    }

    .theory-info {
      padding-left: 120px;
    }
  }

  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .driving-footer {
    .footer-bottom {
      padding: 50px 0;
    }

    .footer-top {
      padding-left: 0;
      position: relative;
      padding-top: 50px;

      .footer-list li {
        margin-right: 30px;
      }

      .footer-border-icon {
        display: none;
      }
    }
  }

  .home-categories-section {
    padding-left: 100px;
    padding-top: 100px;
    padding-bottom: 100px;

    .categories-section-info .categories-section-text {
      width: 60%;
      padding-left: 80px;
      padding-right: 200px;
    }
  }

  .driving-racing-section {
    padding: 100px 0 50px 100px;
  }

  .home-section {
    .home-section-left {
      .user-card {
        width: 320px;
        padding: 30px;
        margin-right: 40px;
      }

      .user-card-sm {
        width: 300px;
        height: 210px;
      }

      .home-user-section {
        padding-left: 100px;
        display: flex;
      }
    }

    .home-section-right {
      .join-info {
        padding-left: 150px;
        padding-right: 100px;

        .home-section-title {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .home-section.driving-join-section .home-section-left {
    width: 60%;
  }

  .home-section.driving-join-section .home-section-right {
    width: 40%;
    .join-info {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .home-section.driving-join-section .home-section-left .home-user-section {
    padding-left: 60px;
  }

  .home-section .home-section-left {
    width: 40%;
  }

  .home-section .home-section-right {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .home-section.driving-join-section {
    .home-section-left {
      float: none;
      width: 100%;

      .home-user-section {
        .user-card-left {
          width: 50%;
        }

        .user-card-right {
          padding-top: 30px;
          width: 50%;
        }
      }
    }

    .home-section-right {
      float: none;
      width: 100%;
      padding: 0 100px;

      .join-info {
        padding-left: 0;
        padding-right: 0;

        .home-section-title {
          margin-bottom: 20px;
        }

        .driving-btn {
          margin-top: 50px;
        }
      }
    }
  }

  .driving-join-section {
    padding: 100px 0 200px 0;
  }

  .driving-racing-section .racing-info {
    padding-right: 80px;
  }

  .home-section .home-section-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 70px;
  }

  .driving-theory-section {
    padding-top: 100px;
    padding-bottom: 100px;

    .theory-text {
      padding-right: 40px;
    }

    .theory-info {
      padding-left: 20px;
    }

    .theory-details {
      display: flex;
      padding-left: 20px;
      padding-top: 90px;
      padding-right: 20px;
    }
  }

  .home-categories-section {
    padding-left: 20px;
    padding-top: 60px;
    padding-bottom: 60px;

    .categories-section-info {
      margin-bottom: 60px;

      .categories-section-text {
        width: 60%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .categories-wrapper {
    .overflow .categories-swipe .swipe-block {
      display: flex;
      flex-direction: column;
      width: 400px;
      min-height: 300px;
      padding: 30px 40px;
      flex-shrink: 0;
      border-right: 1px solid #f4f5f8;

      .head-block p.description {
        padding-left: 20px;
        font-size: 18px;
      }

      p.options {
        font-size: 12px;
        padding: 25px 20px 20px 78px;
      }
    }

    .left-side {
      padding: 30px 25px;
    }
  }

  .driving-footer .footer-top .footer-list {
    height: auto;
  }
}

@media only screen and (max-width: 970px) {
  .driving-theory-section {
    flex-flow: column;

    .home-section-left {
      width: 100%;
      float: none;
    }

    .home-section-right {
      width: 100%;
      float: none;
    }
  }

  .driving-racing-section {
    padding: 100px 0 50px 50px;

    .racing-section-text {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    .racing-info {
      padding-right: 50px;

      .home-section-title {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 30px;
      }
    }
  }

  .home-section.driving-join-section {
    padding: 50px 0 200px 0;

    .home-section-left .home-user-section {
      padding-left: 50px;
    }

    .home-section-right {
      padding: 0 50px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .home-section {
    .home-section-title {
      font-size: 33px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    .theory-text {
      padding-right: 0;
    }

    .theory-details {
      padding-top: 20px;
    }

    .theory-info {
      padding: 0 20px;
    }

    .home-section-right,
    .home-section-left {
      float: none;
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  .home-categories-section {
    .categories-section-info {
      display: block;

      .categories-section-text,
      .categories-section-title {
        width: 100%;
        padding: 0;
      }
    }
  }

  .driving-footer .footer-top {
    display: flex;
    flex-wrap: wrap;

    .footer-heading {
      margin-bottom: 10px;
    }

    .footer-col {
      float: none;
      width: 50%;
      margin-bottom: 20px;

      .footer-list li {
        margin-right: 0;
      }
    }
  }

  .home-section.driving-join-section {
    .home-section-left {
      .home-user-section {
        .user-card-left {
          .user-card {
            width: 300px;
            padding: 20px;
            margin-right: 0;
          }
        }

        .user-card-right {
          padding-top: 7px;

          .user-card-sm {
            width: 280px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 670px) {
  .home-section.home-categories-section {
    .categories-section-info {
      padding-right: 20px;
    }
  }

  .home-section.driving-join-section {
    .home-section-left {
      .home-user-section {
        .user-card-left {
          .user-card {
            width: 240px;
          }
        }

        .user-card-right {
          padding-top: 0;

          .user-card-sm {
            width: 240px;
            height: 200px;
          }
        }
      }
    }
  }

  .animation-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    svg {
      transform: scale(2);
      width: 100%;
    }
  }
}

@media only screen and (max-width: 570px) {
  section.home-section.driving-racing-section.right-decoration {
    display: flex;
    flex-flow: column-reverse;

    .home-section-right {
      img {
        max-width: 100%;
      }

      .racing-img {
        margin-bottom: 10px;
      }
    }

    .racing-info {
      padding-right: 0;
    }

    padding: 50px 20px 50px 20px;
  }

  .home-section.driving-join-section {
    .home-section-left {
      .home-user-section {
        padding: 0 20px;
        flex-flow: column;

        .user-card-left {
          width: 100%;

          .user-card {
            width: 100%;
            margin-bottom: 20px;

            .user-img {
              margin-bottom: 20px;
            }

            .user-icons {
              padding: 20px 0 10px 0;
            }
          }
        }

        .user-card-right {
          padding-top: 0;
          width: 100%;

          .user-card-sm {
            width: 100%;
            height: 200px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .home-section-right {
      padding: 0 20px;

      .join-info {
        .driving-btn {
          margin-top: 30px;
        }

        .home-section-title {
          font-size: 30px;
          line-height: 45px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .driving-footer .footer-top .scroll-up {
    top: 10px;
    z-index: 1;
  }

  .driving-animation-section {
    .animation-bg {
      .animation-info-box {
        h1 {
          font-family: TBCXMedium;
          font-size: 20px;
          line-height: 40px;
          margin-bottom: 20px;

          span {
            font-size: 40px;
          }
        }

        .driving-btn {
          padding: 27px 41px;
        }
      }
    }
  }

  .driving-theory-section {
    padding-top: 50px;
    padding-bottom: 50px;

    .theory-details {
      flex-flow: column-reverse;

      .theory-img {
        margin-left: 0;
        padding: 0 0 20px;
        text-align: center;
      }
    }

    .theory-themes {
      text-align: center;
      justify-content: stretch;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .theory-item {
      max-width: 360px;
      width: 100%;
      padding: 0 20px;
    }

    &.home-section {
      p {
        font-size: 14px;
      }

      p + p {
        margin-top: 15px;
      }

      .theory-info {
        .home-section-title {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 30px;
        }

        .tickets-all {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;

          .tickets-count {
            font-size: 40px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .home-categories-section {
    padding-top: 50px;
    padding-bottom: 50px;

    .categories-section-info {
      margin-bottom: 30px;
      padding-right: 20px;

      .categories-section-text p {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        line-height: 20px;
      }

      .categories-section-title h2 {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 10px;
      }
    }
  }

  .categories-wrapper {
    .left-side {
      display: none;
    }

    .overflow .categories-swipe .swipe-block {
      width: 300px;
      min-height: 300px;
      padding: 20px 20px;

      .head-block {
        p.caption {
          width: 50px;
          height: 50px;
          font-size: 28px;
        }

        p.description {
          padding-left: 20px;
          font-size: 16px;
        }
      }

      p.options {
        padding: 15px 0 15px 78px;
      }

      .image-block {
        height: 180px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .driving-animation-section {
    .animation-bg {
      .animation-info-box {
        padding: 0 20px;

        .driving-btn {
          padding: 19px 40px;
          font-size: 16px;
        }

        h3 {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 30px;
        }

        h1 {
          font-size: 22px;
          line-height: 40px;
          margin-bottom: 10px;

          span {
            font-size: 40px;
          }
        }
      }
    }
  }

  .home-section .theory-details {
    padding-top: 20px;

    .theory-themes {
      width: 100%;

      .more-btn {
        width: 100%;
        height: auto;
        padding: 15px 0;
        font-size: 16px;
      }

      .theory-item {
        width: 100%;
      }
    }
  }

  .home-section.driving-join-section .home-section-right .join-info .driving-btn {
    margin-top: 30px;
    width: 100%;
  }

  .home-categories-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .driving-footer {
    .footer-top {
      .footer-col {
        float: none;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .footer-bottom {
      .top-ge {
        float: none;
        text-align: center;
      }

      .footer-copyright {
        float: none;
        text-align: center;
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .driving-join-section,
  .driving-racing-section,
  .home-categories-section,
  .driving-theory-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .home-categories-section .categories-section-info {
    margin-bottom: 50px;
  }

  .categories-wrapper .overflow .categories-swipe .swipe-block {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: auto;

    p.options {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
