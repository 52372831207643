.c-select-v2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  .overflow {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 37px;
  }
  .my__control--menu-is-open,
  .my__control {
    width: 100%;
    position: relative;
    box-shadow: none;
    background-color: #ffffff;
    border-color: #dadce0;
    min-height: 58px;
    z-index: 9;
    &:hover {
      border-color: #dadce0;
    }
  }
  .my__control {
    border-radius: 30px;
    transition: none;
  }
  .my__control--menu-is-open {
    border-radius: 30px 30px 0 0;
    border-bottom-color: #fff;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  .my__placeholder {
    color: rgba($color: #181a26, $alpha: 0.5);
    font-family: TBCXMedium;
    font-size: 14px;
    margin-left: 0;
    margin-right: 0;
  }
  .my__single-value {
    color: #181a26;
    font-family: TBCXRegular;
    font-size: 14px;
    margin-left: 0;
    margin-right: 0;
    max-width: 70%;
  }
  .my__menu-notice--no-options {
    font-family: TBCXRegular;
    color: #181a26;
    font-size: 14px;
  }
  .my__value-container {
    padding: 0 0 0 30px;
  }
  .my__indicator-separator {
    display: none;
  }
  .my__indicator {
    padding: 0 26px;
    img {
      width: 13px;
    }
  }
  .my__control--menu-is-open .my__indicator {
    transform: rotate(180deg);
  }
  .my__menu {
    background-color: #ffffff;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
    margin-bottom: 0;
    margin-top: 0;
    border: 1px solid #dadce0;
    border-top: none;
    overflow: hidden;
  }
  .my__menu-list {
    padding-bottom: 0;
    padding-top: 0;
    padding: 0 30px;
  }
  .my__menu-list::-webkit-scrollbar {
    width: 0;
  }
  .my__option {
    transition: color 0.2s ease;
  }
  .my__option,
  .my__option--is-focused,
  .my__option--is-selected {
    font-size: 13px;
    font-family: TBCXRegular;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding: 18px 0;
    background-color: transparent;
    border-top: 1px solid #dadce0;
  }
  .my__option--is-focused,
  .my__option--is-selected {
    color: #FD4100;
    &:active {
      background-color: transparent;
    }
  }
  i {
    display: flex;
    position: absolute;
    top: -9px;
    left: 0;
    z-index: 9;
    font-style: normal;
    color: #637279;
    pointer-events: none;
    font-size: 12px;
    background-color: #ffffff;
    padding: 0 6px;
    margin: 0 25px;
  }
}
