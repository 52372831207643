.profile {
  background-color: #f5f6fa;
  .container {
    padding: 14px 0;
  }
  & > .container {
    padding: 24px 0 70px 0;
    display: flex;
  }
  .left-side {
    width: 1060px;
    padding-right: 20px;
    flex-shrink: 0;
    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
      height: 230px;
      padding: 0 40px;
      background-color: #ffffff;
      .personal {
        display: flex;
        align-items: center;
        .avatar {
          position: relative;
          display: flex;
          justify-content: center;
          .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 112px;
            height: 112px;
            background-image: url('../../svgs/avatar-frame.svg');
            background-repeat: no-repeat;
            background-size: 112px 96px;
            img,
            svg {
              display: flex;
              width: 60px;
              height: 60px;
              object-fit: cover;
              border-radius: 100%;
            }
          }
          .reward {
            position: absolute;
            top: 100%;
            margin-top: -33px;
            .reward-circle {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
              background-color: #FD4100;
              border-radius: 100%;
              img {
                width: 14px;
                height: 22px;
              }
            }
            p {
              text-align: center;
              font-family: TBCXMedium;
              font-size: 12px;
              letter-spacing: 0.55px;
              color: #637279;
              padding-top: 6px;
            }
          }
        }
        .info {
          padding: 0 0 2px 24px;
          p {
            display: flex;
            flex-direction: column;
            span {
              font-family: TBCXMedium;
              font-size: 18px;
              color: #181a26;
              text-transform: capitalize;
              padding-bottom: 2px;
            }
            em {
              font-family: TBCXMedium;
              font-size: 12px;
              color: #a5b4bd;
              text-transform: uppercase;
              font-style: normal;
            }
          }
        }
      }
      .position {
        position: relative;
        display: flex;
        align-items: center;
        width: 360px;
        height: 60px;
        border-radius: 16px;
        background-color: rgba($color: #e3e5e9, $alpha: 0.4);
        i.process {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-radius: 16px;
          background-color: #FD4100;
          z-index: 1;
          &.lvl-1 {
            width: 19%;
          }
          &.lvl-2 {
            width: 50%;
          }
          &.lvl-3 {
            width: 100%;
          }
        }
        img.icon {
          position: relative;
          z-index: 2;
          width: 38px;
          height: 38px;
          margin: 0 15px;
        }
        p {
          position: relative;
          z-index: 2;
          font-family: TBCXRegular;
          font-size: 12px;
          letter-spacing: 0.46px;
          color: rgba($color: #181a26, $alpha: 0.7);
          padding: 0 10px;
        }
      }
    }
    .activities {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      min-height: 535px;
      border-radius: 16px;
      padding: 32px 0 43px 0;
      background-color: #ffffff;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 40px 0 44px;

        p.title {
          font-family: TBCXMedium;
          font-size: 25px;
          color: rgba($color: #181a26, $alpha: 0.7);
        }
        .period {
          display: flex;
          align-items: center;
          p {
            font-size: 12px;
            color: #a5b4bd;
            padding-right: 16px;
          }
          .select-type-1-mod {
            width: 140px;
            .my__control {
              min-height: 41px;
              border-radius: 21px;
              border-color: #e4e4e4;
              background-color: rgba(228, 228, 228, 0.05);
            }
            .my__control--menu-is-open {
              border-radius: 21px 21px 0 0;
              border-bottom-color: transparent;
            }
            .my__menu {
              border-color: #e4e4e4;
            }
            .my__value-container {
              padding: 0 0 0 16px;
            }
            .my__indicator {
              padding: 0 19px 0 11px;
            }
            .my__single-value {
              font-size: 13px;
              color: #181a26;
            }
            .my__control--menu-is-open {
              .my__indicator {
                padding: 0 11px 0 19px;
              }
            }
          }
        }
      }
      .statistics {
        display: flex;
        justify-content: space-between;
        padding: 84px 22px 0 22px;
        .wrap {
          .charts {
            display: flex;
            align-items: flex-end;
            .pillar {
              position: relative;
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              width: 82px;
              height: 262px;
              margin: 0 9px;
              border-radius: 10px;
              transition: background-color 0.2s ease, box-shadow 0.2s ease;
              &:hover {
                background-color: #ffffff;
                box-shadow: 0 18px 40px 0 rgba(96, 94, 111, 0.1);
                .placeholder {
                  display: block;
                }
                .text p {
                  color: #181a26;
                  &:last-child {
                    opacity: 1;
                  }
                }
              }
              .placeholder {
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-bottom: 14px;
                transform: translate(-50%, 0%);
                border-radius: 15px;
                box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
                background-color: #ffffff;
                padding: 9px 30px 13px 17px;
                display: none;
                &::after {
                  content: '';
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: 12px;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 6px solid #ffffff;
                }
                p {
                  display: flex;
                  align-items: center;
                  &:first-child span::before {
                    background-color: #29b14e;
                  }
                  &:last-child span::before {
                    background-color: #ff5b3a;
                  }
                  span {
                    position: relative;
                    display: flex;
                    width: 100%;
                    font-size: 12px;
                    color: #637279;
                    padding-left: 15px;
                    &::before {
                      content: '';
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      margin: auto;
                      width: 6px;
                      height: 6px;
                      border-radius: 100%;
                    }
                  }
                  i {
                    display: flex;
                    font-style: normal;
                    font-family: TBCXMedium;
                    font-size: 14px;
                    color: #181a26;
                    padding-left: 8px;
                  }
                }
              }
              .indicator {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                flex-shrink: 1;
                padding: 21px 0 7px 0;
                .low,
                .high {
                  display: flex;
                  height: 100%;
                  padding: 3px 0;
                  i {
                    display: flex;
                    width: 12px;
                    border-radius: 6px;
                  }
                }
                .low i {
                  background-color: #ff5b3a;
                }
                .high i {
                  background-color: #29b14e;
                }
                .no i {
                  background-color: #f4f5f6;
                }
              }
              .text {
                flex-grow: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 8px 0 15px 0;
                p {
                  font-size: 14px;
                  color: #a5b4bd;
                  text-align: center;
                  transition: color 0.2s ease, opacity 0.2s ease;
                  &:last-child {
                    opacity: 0;
                  }
                }
              }
            }
          }
          .result {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 48px 29px 0 29px;
            .block {
              display: flex;
              align-items: center;
              padding: 0 10px;
              &.block-all p span:first-child {
                color: #FD4100;
              }
              p::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -26px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
              }
              &.block-true {
                justify-content: center;
                p::before {
                  background-color: #29b14e;
                }
                p span:first-child {
                  color: #29b14e;
                }
              }
              &.block-false {
                justify-content: flex-end;
                p::before {
                  background-color: #ff5b3a;
                }
                p span:first-child {
                  color: #ff5b3a;
                }
              }
              p {
                position: relative;
                display: flex;
                align-items: center;
                span {
                  display: flex;
                  &:first-child {
                    font-family: TBCXRegular;
                    font-size: 16px;
                  }
                  &:last-child {
                    font-size: 13px;
                    color: #a5b4bd;
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }
        .time {
          flex-shrink: 0;
          padding-top: 32px;
          .icon {
            padding: 55px 86px 55px 105px;
            border-left: 1px solid #e3e5e9;
            svg {
              width: 58px;
              fill: #e3e5e9;
              &.filled {
                fill: #FD4100;
              }
            }
          }
          p {
            font-size: 18px;
            text-align: center;
            color: #181a26;
            padding-top: 97px;
          }
        }
      }
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    line-height: 100%;
    flex-shrink: 1;
    padding-left: 20px;
    .terminated-ticket {
      position: relative;
      z-index: 2;
      padding: 30px 40px 27px 40px;
      display: flex;
      flex-direction: column;
      height: 230px;
      border-radius: 16px;
      background-color: #ffffff;
      transition: border-radius 0.5s ease;
      p.title {
        font-family: TBCXMedium;
        font-size: 16px;
        color: rgba($color: #181a26, $alpha: 0.8);
      }
      .ticket-counter {
        display: flex;
        padding: 46px 0 61px 0;
        .ticket-true,
        .ticket-false {
          width: 50%;
          display: flex;
          align-items: center;
          i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            border-radius: 16px;
          }
          p {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
            span {
              display: flex;
              font-family: TBCXBold;
              font-size: 16px;
              padding-bottom: 3px;
            }
            b {
              font-size: 12px;
              color: #a5b4bd;
              font-weight: normal;
            }
          }
        }
        .ticket-true {
          i {
            background-color: rgba($color: #29b14e, $alpha: 0.1);
            svg {
              height: 11px;
              path {
                fill: #29b14e;
              }
            }
          }
          p {
            span {
              color: #29b14e;
            }
          }
        }
        .ticket-false {
          i {
            background-color: rgba($color: #ff5b3a, $alpha: 0.1);
            svg {
              height: 12px;
              path {
                fill: #ff5b3a;
              }
            }
          }
          p {
            span {
              color: #ff5b3a;
            }
          }
        }
      }
      .pg-bar {
        position: relative;
        height: 5px;
        border-radius: 2.5px;
        background-color: #f3f3f4;
        display: flex;
        p {
          display: flex;
          position: absolute;
          bottom: 100%;
          left: 0;
          margin-bottom: 4px;
          span {
            display: flex;
            font-family: TBCXBold;
            &:first-child {
              font-size: 14px;
              letter-spacing: 1px;
              color: #181a26;
            }
            &:last-child {
              font-size: 10px;
              letter-spacing: 0.71px;
              color: #a5b4bd;
              padding-left: 3px;
            }
          }
        }
        i {
          display: flex;
          width: 10%;
          border-radius: 2.5px;
          background-color: #FD4100;
        }
      }
      .ticket-continue {
        display: flex;
        overflow: hidden;
        height: 0;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0px 40px 40px 0px rgba(176, 174, 189, 0.1);
        border-radius: 0 0 16px 16px;
        transition: padding 0.1s ease;
        button {
          height: 60px;
          border-radius: 30px;
          padding: 0 30px;
          border: solid 1px rgba(255, 146, 32, 0.2);
          background-color: rgba(255, 134, 0, 0.05);
          font-family: TBCXMedium;
          font-size: 14px;
          color: #FD4100;
          opacity: 0;
          transition: opacity 0.2s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:hover {
        border-radius: 16px 16px 0 0;
        transition: border-radius 0s ease;
        .ticket-continue {
          height: auto;
          padding: 6px 0 34px 0;
          button {
            transition: opacity 1s ease;
            opacity: 1;
          }
        }
      }
      .no-result {
        flex-direction: column;
        background-color: #ffffff;
        p {
          font-size: 14px;
          line-height: 1.57;
          color: #637279;
          padding-bottom: 37px;
        }
        a,
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 220px;
          height: 60px;
          border-radius: 30px;
          border: solid 1px rgba(255, 146, 32, 0.2);
          background-color: rgba(255, 134, 0, 0.05);
          font-family: TBCXMedium;
          font-size: 14px;
          color: #FD4100;
        }
      }
    }
    .last-wrongs {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      border-radius: 16px;
      padding: 43px 40px 22px 40px;
      background-color: #ffffff;
      min-height: 535px;
      p.title {
        font-family: TBCXMedium;
        font-size: 16px;
        color: rgba($color: #181a26, $alpha: 0.8);
      }
      .wrong-list {
        display: flex;
        flex-direction: column;
        padding: 62px 0 0 0;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 21px;
          // &:hover {
          //   span {
          //     box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
          //     background-color: #FD4100;
          //     color: #ffffff;
          //   }
          //   p {
          //     color: #FD4100;
          //   }
          // }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            flex-shrink: 0;
            height: 44px;
            border-radius: 16px;
            font-family: TBCXMedium;
            font-size: 14px;
            letter-spacing: 0.3px;
            color: #637279;
            background-color: #f3f3f4;
            transition: all 0.2s ease;
          }
          p {
            position: relative;
            font-size: 12px;
            line-height: 1.42;
            color: #637279;
            padding-left: 30px;
            transition: color 0.2s ease;
            i {
              display: block;
              width: 120px;
              height: 4px;
              border-radius: 2px;
              background-color: #f3f3f4;
              margin: 3px 0;
              &:first-child {
                width: 120px;
              }
              &:last-child {
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
  .no-result {
    border-radius: 16px;
    background-color: rgba($color: #ffffff, $alpha: 0.4);
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 18px;
      line-height: 1.56;
      color: #637279;
      text-align: center;
    }
  }
}

@media all and (max-width: 1700px) and (min-width: 1100px) {
  .bread-crumbs .container {
    padding: 0 20px;
  }
  .profile > .container {
    padding: 24px 20px 70px 20px;
  }
}

@media all and (max-width: 1600px) and (min-width: 1100px) {
  .profile .left-side {
    width: 70%;
  }
}

@media all and (max-width: 1500px) {
  .profile .left-side .activities .statistics .wrap {
    width: 100%;
  }
  .profile .left-side .activities .statistics .wrap .charts {
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 1500px) and (min-width: 1100px) {
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 60px;
  }
}

@media all and (max-width: 1300px) and (min-width: 1100px) {
  .profile .left-side .activities .statistics .time .icon {
    padding: 55px 85px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 50px;
  }
}

@media all and (max-width: 1200px) and (min-width: 1100px) {
  .profile .left-side .user .position {
    width: 300px;
  }
  .profile .left-side {
    width: 65%;
    padding-right: 10px;
  }
  .profile .right-side {
    padding-left: 10px;
  }
  .profile .right-side .last-wrongs,
  .profile .left-side .activities {
    margin-top: 20px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 40px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar .text p {
    word-spacing: 40px;
  }
}
@media all and (max-width: 1100px) {
  .bread-crumbs .container {
    padding: 0 20px;
  }
  .profile > .container {
    flex-direction: column;
    padding: 24px 20px 70px 20px;
  }
  .profile .left-side {
    width: 100%;
    padding-right: 0;
  }
  .profile .right-side {
    padding: 20px 0 0 0;
  }
}

@media all and (max-width: 1000px) {
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 60px;
  }
}

@media all and (max-width: 1000px) {
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 50px;
  }
}

@media all and (max-width: 800px) {
  .profile .left-side .user .position {
    width: 300px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 40px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar .text p {
    word-spacing: 40px;
  }
  .profile .left-side .activities .statistics .time p {
    padding-top: 85px;
  }
  .profile .left-side .activities .statistics .time .icon {
    padding: 60px;
  }
}

@media all and (max-width: 700px) {
  .profile .left-side .user {
    flex-direction: column;
    height: auto;
    padding: 40px 20px;
  }
  .profile .left-side .user .position {
    margin-top: 40px;
    width: 80%;
  }
  .profile .left-side .activities .head p.title {
    font-size: 22px;
    padding-right: 20px;
  }
  .profile .left-side .activities .statistics {
    flex-direction: column;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 50px;
  }
  .profile .left-side .activities .statistics .time p {
    padding-top: 20px;
  }
  .profile .left-side .activities .statistics .time .icon {
    border-left: none;
    display: flex;
    justify-content: center;
  }
}

@media all and (max-width: 600px) {
  .profile .left-side .activities .head {
    padding: 0 20px;
  }
  .profile .left-side .activities .head p.title {
    font-size: 20px;
  }

  .profile .left-side .activities .statistics .wrap .result {
    .block {
      width: 100%;
      padding: 0;
      justify-content: flex-start !important;
      padding: 10px 0;
    }
    .block.block-all {
      p::before {
        background-color: #FD4100;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .profile .left-side .activities .head {
    flex-direction: column;
    align-items: flex-start;
  }
  .profile .left-side .activities .head p.title {
    margin-bottom: 20px;
  }
  .profile .left-side .activities .statistics {
    padding: 54px 15px 0 15px;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 40px;
  }
  .profile .left-side .user .personal .avatar .circle {
    width: 92px;
    height: 92px;
    background-size: 92px 76px;
  }
  .profile .left-side .user .personal .avatar .circle svg {
    width: 50px;
    height: 50px;
  }
  .profile .left-side .user .position {
    width: 90%;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 35px;
  }
}

@media all and (max-width: 400px) {
  .profile .left-side .user .personal {
    flex-direction: column;
  }
  .profile .left-side .user .personal .info {
    padding: 20px 0 0 0;
  }
  .profile .left-side .activities .statistics .wrap .charts .pillar {
    width: 30px;
    margin: 0 5px;
  }
  .profile .left-side .user .position {
    width: 100%;
  }
  .profile .right-side .terminated-ticket {
    padding: 30px 20px 27px 20px;
  }
  .profile .right-side .last-wrongs {
    padding: 43px 20px 22px 20px;
  }
  .profile .no-result p {
    font-size: 14px;
  }
}
