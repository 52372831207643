header.header {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 7;
  transition: box-shadow 0.2s ease;
  .implements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    width: 100%;
    margin: auto;
    padding: 25px 0;
    h1.logo {
      display: flex;
      a {
        display: flex;
        img {
          width: 131px;
          height: 40px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .space-between {
      flex-basis: 100%;
    }
    .non-stretchable {
      display: flex;
      align-items: center;
      .auth-block {
        display: flex;
        a.sign-in {
          display: flex;
          align-items: center;
          height: 40px;
          border-radius: 30px;
          background-color: rgba($color: #FD4100, $alpha: 0.1);
          border: none;
          padding: 0 36px 0 30px;
          transition: background-color 0.2s ease;
          &:hover {
            background-color: #FD4100;
            svg path {
              fill: #ffffff;
            }
            span {
              color: #ffffff;
            }
          }
          svg {
            display: flex;
            width: 16px;
            height: 16px;
            path {
              fill: #FD4100;
              fill-rule: nonzero;
              transition: fill 0.2s ease;
            }
          }
          span {
            display: flex;
            font-family: TBCXMedium;
            font-size: 13px;
            letter-spacing: 0.7px;
            color: #FD4100;
            padding: 1px 0 0 22px;
            transition: color 0.2s ease;
          }
        }
        .profile-box {
          display: flex;
          position: relative;
          button.profile-btn {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: none;
            background-color: white;
            padding: 0;
            transition: opacity 0.2s ease;
            &:hover {
              opacity: 0.9;
            }
            img,
            svg {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 100%;
            }
          }
          .profile-nav {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 9;
            top: 100%;
            right: 0;
            margin-top: 10px;
            border-radius: 14px;
            box-shadow: 0 10px 30px 0 rgba(44, 46, 85, 0.13);
            background-color: #ffffff;
            .haed {
              display: flex;
              border-bottom: 1px solid #edf0f2;
              p {
                display: flex;
                width: 100%;
                flex-direction: column;
                padding: 16px 24px;
                font-family: TBCXMedium;
                white-space: nowrap;
                span {
                  display: flex;
                  font-size: 18px;
                  color: #181a26;
                  text-transform: capitalize;
                  margin-bottom: 2px;
                  max-width: 180px;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: pre-wrap;
                }
                em {
                  display: flex;
                  font-size: 12px;
                  color: #a5b4bd;
                  text-transform: uppercase;
                  font-style: normal;
                }
              }
            }
            ul {
              display: flex;
              flex-direction: column;
              padding: 11px 0;
              li {
                button,
                a {
                  width: 100%;
                  display: flex;
                  padding: 12px 24px;
                  white-space: nowrap;
                  font-family: TBCXMedium;
                  background-color: transparent;
                  font-size: 14px;
                  line-height: 100%;
                  color: #181a26;
                  transition: color 0.2s ease;
                  &:hover {
                    color: #a5b4bd;
                  }
                }
                button {
                  border: none;
                }
              }
            }
          }
        }
      }
      .burger-nav {
        position: relative;
        display: flex;
        margin-left: 24px;
        button {
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border: 1px solid #e4e4e4;
          border-radius: 100%;
          svg {
            width: 11px;
            height: 6px;
          }
          &.is-open,
          &:hover {
            background-color: #181a26;
            svg {
              fill: #ffffff;
            }
          }
        }
        .display {
          position: absolute;
          z-index: 9;
          top: 100%;
          right: 0;
          margin-top: 16px;
          padding: 8px;
          border-radius: 16px;
          box-shadow: 0 10px 30px 0 rgba(44, 46, 85, 0.13);
          background-color: #ffffff;
          ul {
            display: flex;
            flex-direction: column;
            padding: 16px 0;
            li {
              display: flex;
              a {
                display: flex;
                padding: 7px 40px 7px 20px;
                font-family: TBCXMedium;
                font-size: 14px;
                color: #181a26;
                &.active,
                &:hover {
                  color: #FD4100;
                }
              }
            }
          }
        }
      }
    }
    button.shameful-btn {
      display: none;
      position: absolute;
      right: 3px;
      top: 100%;
      margin-top: -12px;
      background-color: transparent;
      border: none;
      padding: 5px 20px;
      img {
        width: 40px;
        height: 20px;
      }
    }
  }
  &.navigable {
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #ffffff;
  }
  &.n-transparent {
    background-color: transparent;
  }
  &.fixed-header {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 6px 34px 0 transparent, inset 0 -1px 0 0 #edf0f2;
  }
  &.overflow-header {
    box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.13);
  }
  &.shameful .implements {
    .space-between {
      display: block;
      z-index: 8;
    }
    button.shameful-btn {
      margin-top: -23px;
      transform: rotate(180deg);
    }
  }
  &.no-shameful button.shameful-btn {
    display: none !important;
  }
}

nav.home-nav {
  display: flex;
  padding: 0 34px;
  ul {
    display: flex;
    align-items: center;
    border-left: 1px solid rgba($color: #637279, $alpha: 0.2);
    padding: 0 17px;
    li {
      padding: 0 18px;
      a {
        display: flex;
        padding: 2px;
        font-family: TBCXMedium;
        font-size: 13px;
        letter-spacing: 0.9px;
        color: #181a26;
        white-space: nowrap;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.ticket-nav {
  display: flex;
  padding: 0 20px;
  .select-type-1 {
    width: 450px;
    margin-right: 20px;
    &:first-child {
      z-index: 99;
    }
  }
}

nav.rs-nav {
  display: flex;
  padding: 0 30px;
  ul {
    position: relative;
    display: flex;
    padding: 0 15px;
    flex-wrap: wrap;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1px;
      height: 35%;
      background-color: rgba($color: #637279, $alpha: 0.2);
    }
    li {
      display: flex;
      align-items: center;
      margin: 0 5px;
      a {
        display: flex;
        align-items: center;
        border-radius: 31px;
        border: solid 1px transparent;
        padding: 10px;
        overflow: hidden;
        img {
          display: flex;
          height: 20px;
        }
        span {
          opacity: 0;
          display: none;
          white-space: nowrap;
          font-family: TBCXMedium;
          font-size: 13px;
          color: #FD4100;
        }
        &.active {
          padding: 20px 31px;
          margin: 0 20px;
          border-color: rgba(255, 146, 32, 0.2);
          background-color: rgba(255, 134, 0, 0.05);
          transition: background-color 0.5s ease 0.5s;
          span {
            display: flex;
            opacity: 1;
            padding: 0 7px 0 25px;
            transition: opacity 0.2s ease;
          }
        }
      }
    }
  }
}

nav.category-nav {
  display: flex;
  padding: 0 30px;
  ul {
    position: relative;
    display: flex;
    padding: 0 15px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1px;
      height: 35%;
      background-color: rgba($color: #637279, $alpha: 0.2);
    }
    li {
      display: flex;
      align-items: center;
      margin: 0 5px;
      a {
        display: flex;
        align-items: center;
        border-radius: 31px;
        border: solid 1px transparent;
        padding: 10px 15px;
        overflow: hidden;
        cursor: pointer;
        i,
        svg,
        span {
          display: flex;
          line-height: 100%;
        }
        svg {
          margin-bottom: 1px;
        }
        i,
        span.title {
          font-family: TBCXRegular;
          font-size: 14px;
          color: #cfd7dc;
        }
        i {
          font-style: normal;
          padding: 2px 7px 0 7px;
        }
        span.title {
          padding-top: 2px;
        }
        span.sub-title {
          font-family: TBCXMedium;
          font-size: 12px;
          color: #FD4100;
          width: 0;
          opacity: 0;
          overflow: hidden;
          letter-spacing: 1.5px;
        }
        &.active {
          border-radius: 31px;
          border-color: rgba(255, 146, 32, 0.2);
          background-color: rgba(255, 134, 0, 0.05);
          padding: 14px 30px 14px 24px;
          margin: 0 14px;
          transition: background-color 0.5s ease 0.5s;
          svg {
            transform: scale(1.3);
            transform-origin: center bottom;
            margin: 6px 10px 0 10px;
            path {
              fill: #FD4100;
            }
          }
          i {
            display: none;
          }
          span.title {
            font-size: 30px;
            color: #FD4100;
            padding: 0 0 1px 24px;
          }
          span.sub-title {
            width: auto;
            padding-left: 8px;
            margin-top: 8px;
            opacity: 1;
            transition: opacity 0.2s ease;
          }
        }
      }
    }
  }
}

nav.ds-nav {
  display: flex;
  padding: 0 30px;
  .select-type-1 {
    width: 100%;
    margin-right: 15px;
    z-index: 2;
    .my__placeholder {
      font-family: TBCXRegular;
      color: #181a26;
      font-size: 12px;
    }
    .my__single-value-icon,
    .my__list-value-icon {
      width: 14px;
      img {
        width: 14px;
        height: 17px;
      }
    }
    .my__single-value-icon {
      display: none;
    }
    .my__menu-list {
      padding: 0px 25px 18px 25px;
    }
    .my__value-container {
      padding: 0 0 0 25px;
    }
    .my__list-value-text {
      padding-left: 21px;
    }
    .my__single-value-text {
      font-size: 13px;
      padding-left: 1px;
    }
    .my__option,
    .my__option--is-focused,
    .my__option--is-selected {
      font-size: 15px;
      border-color: #ffffff;
      padding: 14px 0;
    }
    .my__menu,
    .my__control--menu-is-open {
      border-color: rgba($color: #f1f1f1, $alpha: 0.5);
    }
    .my__control--menu-is-open {
      border-bottom-color: transparent;
    }
    .my__indicator img {
      width: 16px;
    }

    .my__control--menu-is-open .my__indicator {
      img.pin {
        transform: rotate(180deg);
      }
    }
  }
  .category-multi-select {
    position: relative;
    margin-left: 15px;
    flex-grow: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    button.item-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background-color: rgba($color: #e4e4e4, $alpha: 0.05);
      border: solid 1px #e4e4e4;
      padding: 0 15px;
      &:hover {
        opacity: 0.8;
      }
      svg.license-i {
        &.bike-i {
          width: 24px;
          height: 16px;
        }
        &.car-i {
          width: 30px;
          height: 12px;
        }
        &.triler-i {
          width: 30px;
          height: 13px;
        }
        &.bus-i {
          width: 24px;
          height: 12px;
        }
        &.traktor-i {
          width: 21px;
          height: 17px;
        }
        &.bul-i {
          width: 26px;
          height: 16px;
        }
        &.tram-i {
          width: 36px;
          height: 18px;
        }
      }
      i {
        font-family: TBCXRegular;
        font-size: 12px;
        color: rgba($color: #a5b4bd, $alpha: 0.4);
        padding: 3px 11px 0 13px;
      }
      span.title {
        font-family: TBCXRegular;
        font-size: 16px;
        color: #a5b4bd;
        padding: 3px 18px 0 0;
      }
      svg.check-icon {
        width: 20px;
        height: 20px;
        path.p1 {
          fill: rgba($color: #e4e4e4, $alpha: 0.05);
          stroke: #e4e4e4;
          transition: fill 0.2s ease, stroke 0.2s ease;
        }
        path.p2 {
          fill: #a5b4bd;
          transition: fill 0.2s ease;
        }
      }
    }
    .placeholder {
      cursor: pointer;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      border: 1px solid #edf0f2;
      background-color: #ffffff;
      border-radius: 30px;
      height: 60px;
      .selected {
        width: 100%;
        display: flex;
        p {
          font-family: TBCXRegular;
          color: #181a26;
          font-size: 12px;
          padding: 0 25px;
        }
        .wrap {
          padding: 10px 9px;
          .slide {
            display: flex;
            cursor: w-resize;
            max-width: 428px;
            overflow-x: auto;
            border-radius: 30px;
            &::-webkit-scrollbar {
              height: 0;
              display: none;
            }
            -ms-overflow-style: none;
            button.item-btn {
              position: relative;
              flex-shrink: 0;
              min-width: 90px;
              height: 40px;
              margin: 0 5px;
              overflow: hidden;
              transition: border-color 0.2s ease;
              span.title {
                padding: 3px 0 0 0;
              }
              span.delete {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ff5b3a;
                opacity: 0;
                transition: opacity 0.2s ease;
                em {
                  font-family: TBCXRegular;
                  color: #ffffff;
                  font-size: 12px;
                  font-style: normal;
                  padding-right: 9px;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              &:hover {
                border-color: #ff5b3a;
                span.delete {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .indicator {
        display: flex;
        flex-shrink: 0;
        padding: 0 26px;
        img {
          width: 10px;
          transition: transform 0.2s ease;
        }
      }
    }
    .display {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #ffffff;
      border-radius: 0 0 30px 30px;
      box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
      border: 1px solid rgba($color: #f1f1f1, $alpha: 0.5);
      border-top: none;
      overflow: hidden;
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        li {
          padding: 10px;
          button.item-btn {
            min-width: 140px;
            height: 48px;
            &.checked {
              border-color: rgba(255, 146, 32, 0.2);
              background-color: rgba(255, 134, 0, 0.05);
              svg.license-i {
                path {
                  fill: #FD4100;
                }
              }
              i {
                color: rgba($color: #FD4100, $alpha: 0.4);
              }
              span.title {
                color: #FD4100;
              }
              svg.check-icon {
                path.p1 {
                  fill: #FD4100;
                  stroke: #FD4100;
                }
                path.p2 {
                  fill: #ffffff;
                  transition: fill 0.2s ease;
                }
              }
            }
          }
        }
      }
    }
    &.is-open {
      .placeholder {
        border-color: rgba($color: #f1f1f1, $alpha: 0.5);
        border-bottom-color: transparent;
        border-radius: 30px 30px 0 0;
        .indicator img {
          transform: rotateX(180deg);
        }
      }
    }
  }
}

.tip-nav,
.regulator-nav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 0 22px;
  margin: 0 30px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1px;
    height: 22px;
    background-color: #edf0f2;
  }
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    height: 60px;
    margin: 0 9px;
    border-radius: 31px;
    border: solid 1px transparent;
    background-color: transparent;
    font-size: 13px;
    font-family: TBCXMedium;
    color: #637279;
    transition: all 0.2s ease;
    &.active,
    &:hover {
      border: solid 1px rgba(255, 146, 32, 0.2);
      background-color: rgba(255, 134, 0, 0.05);
      color: #FD4100;
    }
  }
}

@media all and (max-width: 1700px) {
  header.header .implements {
    padding: 25px 20px;
  }
  nav.home-nav {
    padding: 0 20px;
  }
  nav.home-nav ul {
    padding: 0 10px;
  }
}

@media all and (max-width: 1600px) {
  nav.home-nav {
    padding: 10px;
    justify-content: center;
  }
  nav.home-nav ul {
    padding: 0;
    border-left: none;
  }
  nav.home-nav ul li {
    padding: 0 15px;
  }
  .ticket-nav {
    justify-content: center;
  }
  .ticket-nav .select-type-1 {
    margin: 0 10px;
  }
}

@media all and (max-width: 1500px) and (min-width: 1350px) {
  nav.home-nav ul li {
    padding: 0 10px;
  }
  nav.home-nav ul li a {
    font-size: 12;
  }
}

@media all and (max-width: 1500px) {
  .ticket-nav .select-type-1 {
    width: 100%;
  }
  nav.category-nav {
    padding: 0 20px;
  }
  nav.category-nav ul {
    padding: 0 10px;
  }
  nav.category-nav ul::before {
    content: none;
  }
}
@media all and (max-width: 1400px) {
  nav.rs-nav {
    padding: 0 20px;
  }
  nav.rs-nav ul {
    justify-content: center;
    width: 100%;
    padding: 0 10px;
  }
  nav.rs-nav ul::before {
    content: none;
  }
  nav.rs-nav ul li a {
    padding: 10px 5px;
  }
  nav.rs-nav ul li a.active {
    padding: 20px;
    margin: 0 10px;
  }
  nav.category-nav {
    padding: 0 10px;
    justify-content: center;
  }
  nav.category-nav ul {
    flex-wrap: wrap;
    padding: 0;
  }
  nav.category-nav ul li a.active {
    padding: 14px 25px 14px 19px;
  }
  nav.category-nav ul li a.active span.title {
    padding: 0 0 1px 14px;
    font-size: 24px;
  }
  nav.category-nav ul li a {
    padding: 10px;
  }
}

@media all and (max-width: 1350px) {
  nav.category-nav ul li a.active {
    margin: 0 5px;
  }
  nav.category-nav ul li a i,
  nav.category-nav ul li a span.title {
    display: none;
  }
}

@media all and (max-width: 1200px) {
  .ticket-nav {
    padding: 0 10px;
  }

  .tip-nav,
  .regulator-nav {
    justify-content: center;
    padding: 0 10px;
    margin: 0 10px;
  }
  .tip-nav::before,
  .regulator-nav::before {
    content: none;
  }
  .tip-nav a,
  .regulator-nav a {
    padding: 0 25px;
  }
}

@media all and (max-width: 1200px) and (min-width: 1100px) {
  nav.rs-nav ul li a.active {
    padding: 10px;
    span {
      width: 0;
      opacity: 0;
      padding: 0;
    }
  }
}

@media all and (max-width: 1100px) {
  .space-between {
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    display: none;
  }
  header.header .implements button.shameful-btn {
    display: block;
  }
}

@media all and (max-width: 800px) {
  header.header .implements .non-stretchable .auth-block a.sign-in {
    width: 40px;
    padding: 0;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  header.header .implements .non-stretchable .auth-block a.sign-in span {
    display: none;
  }
}

@media all and (max-width: 700px) and (max-height: 812px) {
  nav.ds-nav {
    padding: 0 10px;
  }
  .ticket-nav .select-type-1 {
    margin: 0 5px;
  }
  nav.ds-nav .category-multi-select {
    margin-left: 5px;
  }
  nav.ds-nav .select-type-1 {
    margin-right: 5px;
  }
}

@media all and (max-width: 600px) and (max-height: 812px) {
  .ticket-nav {
    flex-direction: column;
    .select-type-1 {
      margin: 5px 0;
    }
  }
}

@media all and (max-width: 600px) {
  header.header.navigable .implements .logo-wrap .navigation p.current {
    padding-right: 20px;
  }
}

@media all and (max-width: 500px) and (max-height: 812px) {
  nav.ds-nav {
    flex-direction: column;
  }
  nav.ds-nav .category-multi-select {
    margin-left: 0;
  }
  nav.ds-nav .select-type-1 {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 500px) {
  header.header .implements button.shameful-btn {
    padding: 5px 13px;
  }
  header.header .implements {
    padding: 25px 13px;
  }
}

@media all and (max-height: 812px) {
  header.header .implements button.shameful-btn {
    padding: 5px 17px;
    img {
      width: 30px;
      height: 15px;
    }
  }
  header.header.shameful .implements button.shameful-btn {
    margin-top: -17px;
  }
  header.header.navigable {
    box-shadow: none;
  }
  header.header {
    .select-type-1 {
      .my__control--menu-is-open,
      .my__control {
        min-height: 48px;
        box-shadow: 0 6px 14px 0 rgba(96, 94, 111, 0.02);
        border-radius: 35px;
      }
      .my__control--menu-is-open {
        border-radius: 35px 35px 0 0;
      }
      .my__menu {
        border-radius: 0 0 35px 35px;
      }
      .my__single-value,
      .my__placeholder {
        font-size: 12px;
      }
      .my__indicator img {
        width: 11px;
      }
    }
  }

  nav.rs-nav ul {
    padding: 0 10px;
    li a.active {
      padding: 13px 20px;
    }
  }
  nav.category-nav ul li a.active {
    padding: 10px 25px;
  }
  nav.ds-nav .category-multi-select .placeholder {
    height: 48px;
  }
  nav.ds-nav .category-multi-select .display ul li {
    padding: 5px;
  }
  nav.ds-nav .category-multi-select .display ul li button.item-btn {
    height: 40px;
  }
  nav.ds-nav .category-multi-select button.item-btn i {
    padding: 3px 7px 0 10px;
  }
  nav.ds-nav .category-multi-select button.item-btn span.title {
    padding: 3px 14px 0 0;
  }
  .tip-nav a,
  .regulator-nav a {
    height: 48px;
    font-size: 12px;
  }
}
