.tips {
  line-height: 100%;
  padding-bottom: 195px;
  .playstation {
    padding: 60px 0;
    .play-box {
      height: 740px;
      background-color: #181a26;
      border-radius: 40px;
      iframe {
        width: 100%;
        height: 100%;
        border-radius: 40px;
      }
    }
  }
  .tip-list {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    .item {
      display: flex;
      flex-direction: column;
      padding-bottom: 140px;
      .head {
        display: flex;
        h2 {
          display: flex;
          align-items: center;
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 90px;
            border-radius: 28px;
            box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
            background-color: #FD4100;
            font-family: TBCXBold;
            font-size: 40px;
            color: #ffffff;
            flex-shrink: 0;
            font-style: normal;
          }
          span {
            font-family: TBCXMedium;
            font-size: 40px;
            color: #181a26;
            padding-left: 40px;
          }
        }
      }
      .overwrap {
        padding: 30px 0 0 0;
        display: flex;
        align-items: center;
        .left,
        .right {
          display: flex;
          width: 50%;
          flex-direction: column;
        }
        .left {
          p {
            padding: 0 140px 0 132px;
            line-height: 1.8;
            font-size: 20px;
            line-height: 1.8;
            color: #181a26;
            padding-bottom: 50px;
          }

          .editor {
            padding: 0 140px 0 132px;
            line-height: 1.8;
            font-size: 20px;
            line-height: 1.8;
            color: #181a26;
            padding-bottom: 50px;
            ul {
              list-style: circle;
              padding-left: 20px;
            }
          }
        }
        .right {
          .play-box {
            height: 500px;
            border-radius: 50px;
            background-color: #181a26;
            iframe {
              width: 100%;
              height: 100%;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1300px) {
  .tips .tip-list .item .overwrap .left p {
    padding: 0 40px 0 32px;
  }
}

@media all and (max-width: 1100px) {
  .tips .tip-list .item .overwrap {
    flex-direction: column;
  }
  .tips .tip-list .item .overwrap .left,
  .tips .tip-list .item .overwrap .right {
    width: 100%;
  }
  .tips .tip-list .item .overwrap .left p {
    padding: 30px 30px 50px 30px;
  }
}

@media all and (max-width: 1000px) {
  .tips .playstation .play-box,
  .tips .tip-list .item .overwrap .right .play-box {
    height: 400px;
  }
}

@media all and (max-width: 700px) {
  .tips .playstation {
    padding: 60px 0 20px 0;
  }
  .tips .tip-list .item {
    padding-bottom: 100px;
  }
  .tips .tip-list .item .head h2 i {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    font-size: 32px;
  }
  .tips .tip-list .item .head h2 span {
    font-size: 30px;
  }
  .tips .tip-list .item .overwrap .left p {
    padding: 25px 20px 40px 20px;
    font-size: 16px;
  }
  .tips .playstation .play-box,
  .tips .tip-list .item .overwrap .right .play-box {
    height: 330px;
  }
}

@media all and (max-width: 600px) {
  .tips .playstation .play-box,
  .tips .tip-list .item .overwrap .right .play-box {
    height: 280px;
  }
  .tips .tip-list .item .overwrap .left .editor {
    padding: 0 40px 50px 32px;
    font-size: 18px;
  }
}

@media all and (max-width: 500px) {
  .tips .playstation .play-box,
  .tips .tip-list .item .overwrap .right .play-box {
    height: 250px;
  }
  .tips .tip-list .item .head h2 i {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    font-size: 26px;
  }
  .tips .tip-list .item .head h2 span {
    font-size: 25px;
    padding-left: 20px;
  }
  .tips .tip-list .item .overwrap .left p {
    padding: 20px 15px 35px 15px;
    font-size: 15px;
  }
}

@media all and (max-width: 400px) {
  .tips .playstation .play-box,
  .tips .tip-list .item .overwrap .right .play-box {
    height: 230px;
  }
  .tips .tip-list .item .head h2 i {
    width: 45px;
    height: 45px;
    font-size: 22px;
  }
  .tips .tip-list .item .head h2 span {
    font-size: 20px;
    padding-left: 15px;
  }
  .tips .tip-list .item .overwrap .left p {
    padding: 15px 10px 30px 10px;
    font-size: 14px;
  }
  .tips .tip-list .item .overwrap {
    padding: 20px 0 0 0;
  }
}

@media all and (max-height: 812px) {
  .tips .playstation .play-box {
    height: 540px;
  }
  .tips .tip-list .item .overwrap .right .play-box {
    height: 400px;
  }
}
