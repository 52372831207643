.select-type-1 {
  input {
    caret-color: transparent;
  }
  .my__control--menu-is-open,
  .my__control {
    position: relative;
    box-shadow: none;
    background-color: #ffffff;
    border-color: #edf0f2;
    min-height: 60px;
    z-index: 9;
    &:hover {
      border-color: #edf0f2;
    }
  }
  .my__control {
    cursor: pointer;
    border-radius: 30px;
    transition: none;
  }
  .my__control--menu-is-open {
    border-radius: 30px 30px 0 0;
    border-bottom-color: #fff;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  .my__placeholder {
    color: rgba($color: #181a26, $alpha: 0.5);
    font-family: TBCXMedium;
    font-size: 14px;
    margin-left: 0;
    margin-right: 0;
  }
  .my__single-value {
    color: #181a26;
    font-family: TBCXRegular;
    font-size: 14px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
  }
  .my__menu-notice--no-options {
    font-family: TBCXRegular;
    color: #181a26;
    font-size: 14px;
  }
  span.my__single-value-icon,
  span.my__single-value-text {
    display: flex;
  }
  span.my__single-value-icon {
    width: 34px;
    svg {
      height: auto;
    }
  }
  span.my__single-value-text {
    padding-left: 26px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
  }
  .my__value-container {
    padding: 0 0 0 30px;
  }
  .my__indicator-separator {
    display: none;
  }
  .my__indicator {
    padding: 0 26px;
    img {
      width: 13px;
    }
  }
  .my__clear-indicator ~ .my__dropdown-indicator {
    display: none;
  }
  .my__control--menu-is-open .my__indicator {
    transform: rotate(180deg);
  }
  .my__menu {
    background-color: #ffffff;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
    margin-bottom: 0;
    margin-top: 0;
    border: 1px solid #edf0f2;
    border-top: none;
    overflow: hidden;
  }
  .my__menu-list {
    padding-bottom: 0;
    padding-top: 0;
    padding: 0 30px;
    max-height: 450px;
  }
  .my__menu-list::-webkit-scrollbar {
    width: 0;
  }
  .my__option {
    transition: color 0.2s ease;
    cursor: pointer;
  }
  .my__option,
  .my__option--is-focused,
  .my__option--is-selected {
    font-size: 13px;
    font-family: TBCXRegular;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding: 18px 0;
    background-color: transparent;
    border-top: 1px solid #edf0f2;
  }
  .my__option--is-focused,
  .my__option--is-selected {
    color: #FD4100;
    svg {
      polygon,
      path {
        fill: #FD4100;
      }
    }
    &:active {
      background-color: transparent;
    }
  }
  .my__list-value-icon,
  .my__list-value-text {
    display: flex;
  }
  .my__list-value-icon {
    width: 34px;
    flex-shrink: 0;
  }
  .my__list-value-text {
    padding-left: 26px;
  }
}

.select-type-1-mod {
  width: 150px;
  line-height: 100%;
  z-index: 1;
  .css-b8ldur-Input {
    margin: 0;
  }
  .my__control {
    min-height: 32px;
    border-radius: 21px;
  }
  .my__control--menu-is-open {
    border-radius: 21px 21px 0 0;
  }
  .my__menu {
    border-radius: 0 0 21px 21px;
  }
  .my__value-container {
    padding: 0 0 0 14px;
  }
  .my__single-value {
    width: 90%;
    display: block;
    color: #637279;
    font-size: 11px;
  }
  .my__indicator {
    padding: 0 14px 0 11px;
    img {
      width: 8px;
    }
  }
  .my__control--menu-is-open .my__indicator {
    padding: 0 11px 0 14px;
  }
  .my__menu-list {
    padding: 0 14px;
  }
  .my__placeholder {
    width: 90%;
    font-size: 11px;
    font-family: TBCXRegular;
    color: #637279;
  }
  .my__single-value,
  .my__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my__option,
  .my__option--is-selected {
    font-size: 11px;
    padding: 8px 0;
  }
}
