.modal-backdrop.show {
  background-color: #181d26;
  opacity: 0.72;
}
.driving-filter {
  display: flex;
  align-items: center;
  margin-left: auto;

  .dropdown-label {
    color: $grey-lighter;
    font-size: 12px;
    margin-right: 16px;
  }
  .dropdown {
    position: relative;
    .dropdown-toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 140px;
      height: 41px;
      border-radius: 21px;
      border: 1px solid #e4e4e4;
      padding: 0 18px;
      color: $dark;
      font-size: 13px;

      i {
        margin-left: auto;
      }
    }

    .dropdown-menu {
      position: absolute;
      background-color: $white;
      border-radius: 21px;
      box-shadow: 0 26px 40px 0 rgba(96, 94, 111, 0.1);
      padding: 40px 18px 10px 18px;
      z-index: 1;
      width: 100%;
      top: 0;
      left: 0;
      border: none;
      min-width: unset;

      li {
        margin-top: 5px;
        a {
          color: $dark;
          font-size: 13px;

          &:hover {
            color: $grey-lighter;
          }
        }
      }
    }
    &.open {
      box-shadow: none;
      .dropdown-toggle {
        i {
          transition: 0.3s all;
        }
      }
    }
  }
}

.driving-dropdown {
  cursor: pointer;
  .dropdown-toggle {
    display: block;
    border-radius: 30px;
    border: solid 1px #edf0f2;
    width: 500px;
    height: 60px;
    position: relative;
    z-index: 111;
    padding: 22px 30px;
    font-size: 13px;
    color: $dark;
    line-height: 1;
    input {
      background-color: transparent;
      border: none;
      height: 60px;
      width: 100%;
      padding: 0 30px;
      font-family: TBCXRegular;
      font-size: 13px;
      color: $dark;

      &::-webkit-input-placeholder {
        font-family: TBCXRegular;
        font-size: 13px;
        color: $dark;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-family: TBCXRegular;
        font-size: 13px;
        color: $dark;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-family: TBCXRegular;
        font-size: 13px;
        color: $dark;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-family: TBCXRegular;
        font-size: 13px;
        color: $dark;
      }
    }
  }
  .dropdown-menu {
    z-index: 11;
    padding-top: 85px;
    border: none;
    border-radius: 30px;
    box-shadow: 0 15px 50px 0 rgba(38, 38, 42, 0.1);
    padding-right: 30px;
    padding-bottom: 30px;
    top: -60px !important;

    .driving-scroll {
      max-height: 305px;
    }

    .dropdown-item {
      font-size: 15px;
      color: #26262a;
      padding: 0;
      margin-bottom: 25px;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
      }
    }
  }
  &.location-dropdown {
    margin-right: 30px;
    .dropdown-toggle {
      padding: 0;
      input {
        background-image: url(../../svgs/marker.svg);
        background-repeat: no-repeat;
        background-size: 16px auto;
        background-position: right 30px center;
      }
    }

    .dropdown-tags {
      display: flex;
      padding: 0 30px 30px 30px;
      .dropdown-tag {
        margin-right: 10px;
        display: inline-block;
        color: rgba($dark, 0.63);
        font-size: 12px;
        padding: 10px 12px;
        background-color: rgba(#a5b4bd, 0.12);
        border-radius: 24px;
      }
    }
    .dropdown-menu {
      .dropdown-item {
        position: relative;
        padding-left: 65px;
        &:before {
          content: '';
          display: block;
          width: 15px;
          height: 17px;
          position: absolute;
          left: 30px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url(../../svgs/marker-orange.svg);
          background-size: cover;
        }
      }
    }

    &.open,
    &.show {
      .dropdown-toggle {
        input {
          background-image: url(../../svgs/clear.svg);
          background-size: 15px auto;
        }
      }
    }
  }

  &.driving-category-dropdown {
    .dropdown-toggle {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-image: url(../../svgs/dropdown-arrow.svg);
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        width: 10px;
        height: 6px;
        transition: 0.3s;
      }
      &.selected {
        padding: 10px;
      }
      .driving-category-item {
        width: 90px;
        display: inline-block;
        background-color: rgba(#a5b4bd, 0.12);
        border-radius: 24px;
        padding: 12px 16px;
        margin-right: 8px;
        .category-icon {
          svg {
            opacity: 0.4;
          }
        }
        .category-type {
          font-family: TBCXMedium;
          font-size: 16px;
          color: rgba(#637279, 0.7);
          &:before {
            content: '/';
            padding-left: 6px;
            margin-right: 6px;
            font-size: 12px;
            opacity: 0.7;
          }
        }

        &.focused {
          background-color: $error;
          span {
            color: $white;
            font-size: 12px;
          }
          svg {
            width: 16px;
            height: auto;
            margin-left: 7px;
          }
        }
      }
    }
    &.open,
    &.show {
      .dropdown-toggle {
        &:after {
          transform: rotate(180deg) translateY(-50%);
          right: 30px;
          width: 10px;
          height: 6px;
        }
      }
    }

    .dropdown-menu {
      padding: 60px 0 20px 20px;

      &.open,
      &.show {
        display: flex;
        flex-wrap: wrap;
      }

      .dropdown-item {
        display: inline-block;
        width: auto;
        margin-bottom: 0;
        .driving-category-item {
          width: 140px;
          margin-top: 20px;
          margin-right: 20px;
          border-radius: 24px;
          padding: 12px 52px 12px 22px;
          border: 1px solid #e4e4e4;
          background-color: rgba(228, 228, 228, 0.05);
          background-image: url(../../svgs/check-outline.svg);
          background-repeat: no-repeat;
          background-size: 20px auto;
          background-position: right 14px center;

          .category-type {
            font-size: 16px;
            color: #a5b4bd;
            &:before {
              content: '/';
              font-size: 12px;
              padding-left: 9px;
              margin-right: 12px;
              opacity: 0.4;
            }
          }

          .category-icon {
            svg {
              max-width: 30px;
              height: auto;
              path {
                fill: #a5b4bd;
                opacity: 1;
              }
            }
          }
          &:hover,
          &.active {
            border: solid 1px rgba(255, 146, 32, 0.2);
            background-color: rgba(255, 134, 0, 0.05);
            background-image: url(../../svgs/check-fill.svg);

            .category-icon {
              svg {
                path {
                  fill: #FD4100;
                  opacity: 1;
                }
              }
            }
            .category-type {
              color: $orange-lighter;
            }
          }
        }
      }
    }
  }

  &.open,
  &.show {
    box-shadow: none !important;
    .dropdown-toggle {
      border: none;
    }
  }
}

.driving-rating {
  fieldset,
  label {
    margin: 0;
    padding: 0;
  }
  body {
    margin: 20px;
  }
  h1 {
    font-size: 1.5em;
    margin: 10px;
  }

  /****** Style Star Rating Widget *****/

  .rating {
    border: none;
  }

  .rating > input {
    display: none;
  }
  .rating > label:before {
    margin: 4px;
    font-size: 20px;
    font-family: FontAwesome;
    display: inline-block;
    content: '\f006';
  }

  .rating > label {
    color: $grey;
    float: right;
  }

  .rating > input:checked ~ label,
  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label {
    color: $orange;
    &:before {
      content: '\f005';
    }
  }

  .rating > input:checked + label:hover,
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label,
  .rating > input:checked ~ label:hover ~ label {
    color: $orange-lighter;
  }
}

.driving-modal {
  .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;
    max-width: unset;
    .modal-content {
      border-radius: 30px;
      position: relative;
      .close {
        padding: 0;
        margin: 0;
        position: absolute;
        top: -34px;
        right: -34px;
        opacity: 0.5;

        svg {
          width: 34px;
          height: auto;
        }
      }
      .modal-header,
      .modal-body,
      .modal-footer {
        padding: 0;
        border: none;
      }
    }
  }
}

.overlay {
  position: relative;
  transition: 0.3s all;
  &:after {
    content: '';
    display: block;
    background-color: rgba(#6a6e78, 0.8);
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
}

.container {
  width: 1600px;
  padding-right: 0;
  padding-left: 0;
  max-width: unset;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
}

ul,
nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

input,
button {
  &:focus {
    outline: none;
  }
}

.gradient-border {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ffffff, #ebebeb);
    border-radius: 12px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: $white;
    border-radius: 11px;
  }
}

.dropdown-toggle {
  &:after {
    content: '';
    border: none;
    margin: 0;
    display: none;
  }
}
.dropdown {
  position: relative;
  .dropdown-menu {
    //display: none;
    z-index: 2;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    box-shadow: 0 15px 50px 0 rgba(38, 38, 42, 0.1);
    margin: 0;
  }
  &.open,
  &.show {
    box-shadow: 0 15px 50px 0 rgba(38, 38, 42, 0.1);
    .dropdown-toggle {
      position: relative;
      z-index: 11;
      background-color: $white;
      border: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      i {
        transform: rotate(-180deg);
      }
    }
    .dropdown-menu {
      display: block;
    }
  }
}
.driving-round-light-button {
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 146, 32, 0.2);
  background-color: rgba(255, 134, 0, 0.05);
  border-radius: 30px;
  color: $orange-lighter;
  font-family: TBCXMedium;
  font-size: 14px;
  &:hover {
    color: $orange-lighter;
  }
}

.driving-user-content {
  background-color: #f5f6fa;
}

.driving-checkbox {
  .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 6px;
      //background-color: #eee;
      background-color: $white;
      border: 1px solid #dadce0;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 3px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: $orange;
      border-color: $orange;
      &:after {
        display: block;
      }
    }
  }
}

.btn-with-icon {
  display: inline-block;
  font-family: TBCXMedium;
  font-size: 14px;
  line-height: 60px;
  color: $orange;
  padding-left: 84px;
  padding-right: 29px;
  position: relative;
  transition: all 0.2s;

  .ticket-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 22px;
    background-color: rgba($orange, 0.1);
    margin-right: 24px;
    position: absolute;
    left: 0;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    background-color: $orange;
    color: $white;
    border-radius: 30px;
    .ticket-icon {
      border-radius: 0;
      background-color: transparent;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

.driving-animation-section {
  margin-top: -120px;
  .animation-bg {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #f4f5f6;
    .animation-info-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 700px;
      width: 100%;
      text-align: center;
      h1 {
        font-family: TBCXMedium;
        font-size: 32px;
        line-height: 80px;
        margin-bottom: 40px;

        span {
          display: block;
          font-size: 48px;
          line-height: 32px;
          color: $orange;
        }
      }

      h3 {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 40px;
      }

      .driving-btn {
        display: inline-block;
        font-family: TBCXMedium;
        font-size: 18px;
        color: $white;
        background-color: $orange;
        border-radius: 38px;
        padding: 20px 32px;
        &:hover {
          background-color: #ff5b22;
        }
      }
    }
  }
}

.home-section {
  @include clearfix();

  &.right-decoration {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 430px;
      background-image: url(../../imgs/right-decoration.png);
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
      top: 100px;
    }

    &.driving-theory-section {
      &:before {
        top: 230px;
      }
    }
  }

  &.left-decoration {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 630px;
      background-image: url(../../imgs/left-decoration.png);
      background-repeat: no-repeat;
      background-size: contain;
      left: 0;
      top: 100px;
    }
  }

  .home-section-left {
    float: left;
    width: 50%;
    position: relative;
    z-index: 1;
  }

  .home-section-right {
    float: left;
    width: 50%;
    position: relative;
    z-index: 1;
  }

  .home-section-title {
    font-family: TBCXMedium;
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 98px;
    display: table-caption;
  }

  .tickets-all {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 28px;
    display: flex;

    .tickets-count {
      color: $success;
      font-family: TBCXBold;
      font-size: 40px;
      margin-right: 26px;
    }
  }

  p {
    font-size: 17px;
    color: rgba($dark, 0.5);
  }

  p + p {
    margin-top: 19px;
  }
}

.driving-theory-section {
  padding-top: 260px;
  padding-bottom: 340px;

  .theory-info {
    padding-left: 260px;
  }

  .theory-text {
    padding-right: 160px;
  }

  .theory-details {
    display: flex;
    padding-left: 80px;
    padding-top: 120px;
    padding-right: 214px;
  }

  .theory-item {
    background-color: $white;
    border-radius: 16px;
    width: 250px;
    height: 100px;
    box-shadow: 0 10px 60px 0 rgba(121, 126, 168, 0.14);
    padding: 0 37px;
    margin-bottom: 24px;
    font-size: 13px;
    display: flex;
    align-items: center;

    .theory-icon {
      margin-right: 35px;

      &.category-icon {
        width: 38px;
        svg {
          path {
            fill: $orange;
            opacity: 1;
          }
        }
      }
      &.sign-icon {
        img {
          width: 28px;
          height: auto;
        }
      }
    }
  }

  .more-btn {
    display: inline-block;
    width: 250px;
    height: 70px;
    color: $white;
    font-size: 18px;
    font-family: TBCXMedium;
    text-align: center;
    background-color: $orange;
    box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
    border-radius: 16px;
    padding: 25px 0;
    &:hover {
      border-radius: 35px;
    }
  }

  .theory-img {
    box-shadow: 0 10px 60px 0 rgba(121, 126, 168, 0.08);
    border-radius: 16px;
    margin-left: 60px;
    overflow: hidden;
    img {
      width: 360px;
      height: auto;
    }
  }
}

.home-categories-section {
  padding-left: 260px;
  padding-top: 108px;
  padding-bottom: 130px;
  background-color: $dark;
  .categories-section-info {
    display: flex;
    align-items: center;
    margin-bottom: 130px;
    .categories-section-title {
      width: 40%;
      h2 {
        font-family: TBCXMedium;
        color: $white;
        font-size: 40px;
        line-height: 60px;
      }
    }
    .categories-section-text {
      width: 60%;
      padding-left: 80px;
      padding-right: 430px;
      p {
        color: rgba($white, 0.5);
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .categories-list-wrapper {
    background-color: $white;
    padding-left: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    position: relative;
    overflow: hidden;

    .web-icon,
    .border-icon {
      position: absolute;
      display: block;
      width: 120px;
      text-align: center;
      left: 0;
    }

    .web-icon {
      top: 25px;
    }
    .border-icon {
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.3;
    }

    .categories-list {
      display: flex;
      li {
        border-left: 1px solid #f3f4f7;
      }
      .category-item {
        width: 570px;
        padding: 40px 30px 40px 60px;
        background-color: $white;

        .category-heading {
          position: relative;
          padding-left: 88px;
          font-family: TBCXMedium;
          font-size: 22px;
          line-height: 27px;
          margin-bottom: 36px;

          span {
            color: $orange;
          }

          .category-icon {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 16px;
            background-color: $orange;
            box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
            font-family: TBCXMedium;
            font-size: 34px;
            color: $white;
            text-align: center;
            line-height: 60px;
          }
        }
        .category-info {
          padding-left: 88px;
          font-family: TBCXRegular;
          font-size: 13px;
          line-height: 24px;
          height: 48px;
          margin-bottom: 40px;

          strong,
          b {
            font-family: TBCXMedium;
          }
        }

        .category-img {
          border-radius: 10px;
          background-color: rgba(#f7f9fc, 0.7);
          width: 450px;
          height: 225px;
          padding: 30px 60px;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}

.driving-racing-section {
  padding: 160px 0 110px 260px;

  .racing-info {
    padding-right: 150px;
  }

  .racing-section-text {
    padding-bottom: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid rgba($dark, 0.1);
  }

  .racing-img {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: -40px 40px 80px 0 rgba(92, 87, 122, 0.08);
    //background-image: linear-gradient(to right, #ffffff, #fafbfd);
    img {
      width: 100%;
      height: auto;
    }
  }
}

.driving-join-section {
  padding: 160px 0 330px 0;
  .home-user-section {
    padding-left: 142px;
    display: flex;

    .user-card-right {
      padding-top: 145px;
    }
    .user-card {
      width: 380px;
      background-color: $white;
      border-radius: 20px;
      box-shadow: 0 10px 60px 0 rgba(121, 126, 168, 0.08);
      padding: 38px 30px;
      margin-right: 40px;

      .user-img {
        margin-bottom: 40px;
        img {
          width: 100%;
          height: auto;
        }
      }

      .user-name {
        font-family: TBCXMedium;
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
      }
      .user-level {
        font-family: TBCXMedium;
        font-size: 14px;
        color: $grey-lighter;
        text-align: center;
      }

      .user-icons {
        padding: 70px 0 30px 0;
        display: flex;
        justify-content: center;

        .user-icon {
          text-align: center;
          padding-left: 40px;
          padding-right: 40px;

          .icon-round {
            border-radius: 100%;
            width: 64px;
            height: 64px;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &.icon-rank {
              background-color: $success;
              box-shadow: 0 9px 24px 0 rgba(41, 177, 78, 0.2);

              img {
                width: 18px;
                height: auto;
              }
            }
            &.icon-sign {
              background-color: $orange;
              box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);

              img {
                width: 34px;
                height: auto;
              }
            }
          }

          .icon-count {
            margin-top: 20px;
            color: $grey;
            font-size: 16px;
            font-family: TBCXMedium;
          }

          &:not(:last-child) {
            .icon-round {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                right: -40px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 1px;
                height: 20px;
                background-color: rgba($grey-lighter, 0.2);
              }
            }
          }
        }
      }
    }

    .user-card-sm {
      width: 380px;
      height: 230px;
      margin-bottom: 40px;
      background-color: $white;
      box-shadow: 0 10px 60px 0 rgba(121, 126, 168, 0.1);
      border-radius: 20px;
      position: relative;

      .card-title {
        font-family: TBCXMedium;
        font-size: 12px;
        position: absolute;
        top: 40px;
        left: 37px;
        max-width: 106px;
      }

      .tickets-number {
        display: block;
        position: absolute;
        top: 80px;
        left: 37px;
        font-family: TBCXBold;
        font-size: 40px;
      }

      .user-activity {
        position: absolute;
        top: 29px;
        right: 30px;
        width: 58px;
        img {
          width: 100%;
          height: auto;
        }
      }

      .chart-img {
        position: absolute;
        bottom: 0;
        left: 0;

        &.chart-today {
          padding: 0 50px 36px 50px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .chart-all-img {
      width: 380px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .join-info {
    padding-left: 150px;
    padding-right: 265px;

    .home-section-title {
      font-size: 60px;
      line-height: 70px;
      margin-bottom: 70px;
    }

    .driving-btn {
      display: inline-block;
      background-color: $orange;
      border-radius: 40px;
      color: $white;
      font-family: TBCXMedium;
      font-size: 18px;
      width: 300px;
      height: 70px;
      text-align: center;
      padding: 24px 0;
      margin-top: 130px;
      &:hover {
        background-color: #ff5b22;
      }
    }
  }
}

.driving-mediumdark-popup {
  background-color: rgba(#181d26, 0.72);
}

.level-content {
  width: 580px;
  text-align: center;
  position: relative;

  .level-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .level-label-wrapper {
    width: 296px;
    margin: 0 auto;
    background-image: url(../../imgs/firework.png);
    background-size: 100% auto;
    padding-top: 62px;
    background-repeat: no-repeat;
    background-position: center 62px;
    position: relative;
  }

  .level-label {
    width: 190px;
    margin: 0 auto;
    padding-top: 52px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .level-heading {
    margin-top: 55px;
    margin-bottom: 18px;
    font-family: TBCXMedium;
    font-size: 40px;
    color: $orange;
  }

  .level-text {
    font-size: 19px;
    color: $grey;
  }

  .level-social-list {
    margin-top: 70px;
    margin-bottom: 60px;

    li {
      display: inline-block;
      margin-left: 9px;
      margin-right: 9px;

      .social-icon {
        display: block;
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.exam-location-content {
  width: 680px;

  .exam-location-details {
    padding: 60px;
  }
  .exam-location-heading {
    font-family: TBCXMedium;
    font-size: 38px;
    margin-bottom: 70px;
  }

  .exam-info {
    display: flex;
    align-items: center;
    margin-bottom: 56px;

    .exam-exact-date {
      font-family: TBCXMedium;
      font-size: 22px;

      i {
        margin-right: 16px;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        display: block;
        font-family: TBCXRegular;
        font-size: 13px;
        color: $grey;
        padding-left: 40px;
      }
    }
  }

  .exam-left-time {
    padding: 14px 24px;
    border-radius: 26px;
    background-color: rgba($orange, 0.1);
    color: $orange;
    font-size: 16px;
    margin-left: auto;
  }

  .exam-phone {
    display: block;
    color: $dark;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .exam-address {
    display: block;
    font-size: 16px;
    font-style: normal;
  }

  .phone-icon,
  .marker-icon {
    margin-right: 19px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .exam-location-map {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;

    .location-pin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.driving-form {
  .driving-form-item {
    margin-bottom: 32px;
    position: relative;
  }

  .driving-label {
    display: none;
    font-size: 12px;
    color: $grey;
    background-color: $white;
    padding: 0 5px;
    position: absolute;
    z-index: 1;
    left: 30px;
    top: -8px;
  }

  .driving-input {
    display: block;
    width: 100%;
    border: 1px solid #dadce0;
    border-radius: 29px;
    height: 58px;
    padding: 0 30px;
    font-size: 15px;

    &:focus {
      &::-webkit-input-placeholder {
        color: transparent;
        opacity: 0;
      }
    }
  }
  .driving-dropdown {
    border-top-left-radius: 29px;
    border-top-right-radius: 29px;
    .dropdown-toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 30px;
      height: 58px;
      border: 1px solid #dadce0;
      border-radius: 29px;
      font-size: 15px;
      color: $grey;

      i {
        margin-left: auto;
      }
    }

    .dropdown-menu {
      border-bottom-left-radius: 29px;
      border-bottom-right-radius: 29px;
      padding: 10px 30px;

      li {
        a {
          font-size: 15px;
          color: $grey;
        }
      }
    }
  }

  .driving-input:focus,
  .driving-dropdown:focus {
    & + .driving-label {
      display: block;
    }
  }

  .driving-form-submit-button {
    margin-top: 64px;
    width: 100%;
    height: 80px;
    border-radius: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: TBCXMedium;
    font-size: 22px;
    box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
    background-color: $orange;

    &:disabled {
      background-color: #e3e5e9;
      box-shadow: none;
    }
  }
}

.ticket-wrapper {
  padding-bottom: 75px;
}
.ticket-item {
  .ticket-img {
    margin-bottom: 27px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;

    img {
      border-radius: 16px;
      width: 100%;
      height: auto;
    }

    .info-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      width: 34px;
      height: 34px;
      top: 18px;
      right: 18px;
      background-image: url(../../imgs/info-icon.png);
      background-size: cover;
      z-index: 1;
    }

    &:hover {
      &:after {
        content: '';
        display: block;
        background-color: rgba($dark, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .info-icon {
        display: block;
      }
    }

    .ticket-info-popup {
      padding: 50px;
      background-color: rgba($dark, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      .info-close {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 18px;

        svg {
          width: 34px;
          height: 34px;

          path {
            opacity: 0.7;
          }
        }
      }
      .ticket-info {
        background-color: $white;
        border-radius: 16px;
        padding: 20px 30px;

        h4 {
          font-family: TBCXMedium;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .info-text {
          height: 190px;
          overflow: auto;
          margin-bottom: 20px;

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .more-btn {
          font-family: TBCXMedium;
          font-size: 14px;
          color: $orange;

          i {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .ticket-question {
    text-align: center;
    font-family: TBCXBold;
    font-size: 15px;
    line-height: 24px;
    color: rgba($dark, 0.9);
    margin-bottom: 60px;
    padding: 0 35px;
  }

  .ticket-answers {
    @include clearfix();
    li {
      cursor: pointer;
      float: left;
      width: 50%;
      font-size: 14px;
      line-height: 20px;
      color: rgba($dark, 0.9);
      position: relative;
      padding-left: 64px;
      padding-right: 35px;
      margin-bottom: 40px;

      .answer-number {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 50px;
        height: 50px;
        background-color: $grey-lighter;
        border-radius: 16px;
        color: $white;
        font-family: TBCXBold;
        font-size: 22px;
        text-align: center;
        line-height: 50px;
      }

      &.disabled {
        cursor: default;
        opacity: 0.4;
      }

      &.done {
        opacity: 1;
        font-family: TBCXBold;
        color: $success;
        padding-left: 74px;

        .answer-number {
          text-indent: -9999px;
          width: 58px;
          height: 58px;
          background-color: $success;
          box-shadow: 0 10px 30px 0 rgba(41, 177, 78, 0.3);
          border-radius: 18px;
          background-image: url('../../svgs/ticket-done.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &.error {
        opacity: 1;
        color: $error;

        .answer-number {
          text-indent: -9999px;
          background-color: $error;
          box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
          background-image: url('../../svgs/ticket-error.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }

  &.ticket-item-sm {
    .ticket-img {
      margin-bottom: 19px;
    }
    .ticket-question {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 20px;
    }

    .ticket-answers {
      li {
        font-size: 11px;
        line-height: 13px;
        padding-left: 50px;
        padding-right: 10px;
        margin-bottom: 30px;

        .answer-number {
          width: 40px;
          height: 40px;
          font-size: 16px;
          line-height: 40px;
          border-radius: 12px;
        }

        &.disabled {
          opacity: 0.3;
        }

        &.done {
          .answer-number {
            background-size: 16px auto;
          }
        }
        &.error {
          .answer-number {
            background-size: 12px auto;
          }
        }
      }
    }
  }
}

.driving-categories-dropdown-wrapper {
  display: flex;
  justify-content: center;
}

.driving-categories-dropdown {
  position: relative;
  z-index: 1;

  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0 37px;
    width: 460px;
    height: 80px;
    background-color: $white;
    box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
    border-radius: 40px;
    text-align: left;
    color: $dark;
    font-family: TBCXMedium;
    font-size: 14px;
    white-space: normal;

    i {
      margin-left: auto;
    }

    .dropdown-menu-item {
      max-width: 290px;
      .category-icon {
        svg {
          path {
            fill: $orange;
            opacity: 1;
          }
        }
      }
    }
  }

  .dropdown-menu-item {
    font-size: 13px;
    padding-left: 70px;
    position: relative;
    span {
      font-family: TBCXBold;
    }

    .category-icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .dropdown-text {
    .dropdown-menu-item {
      font-family: TBCXRegular;
      font-size: 14px;
    }
  }

  .dropdown-menu {
    transform: none !important;
    position: absolute;
    top: 0 !important;
    padding: 80px 36px 0 36px;
    left: 0;
    background-color: $white;
    box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
    border-radius: 40px;
    border: none;

    li {
      border-top: 1px solid #f4f5f6;
      padding: 19px 0;
      text-align: left;
      a {
        display: block;
        color: $dark;

        &:hover {
          .dropdown-menu-item {
            color: $orange;
            .category-icon {
              svg {
                path {
                  fill: $orange;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &.open,
  &.show {
    box-shadow: none;
    .dropdown-toggle {
      color: rgba($dark, 0.5);
      box-shadow: none;
    }

    .dropdown-menu {
      display: block;
      box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
    }
  }
}

.tickets-nav {
  @include clearfix();

  li {
    padding-right: 1px;
    float: left;
    width: 3.333%;
    position: relative;
    transition: 0.3s all;
    a {
      display: block;
      background-color: rgba($grey-lighter, 0.4);
      height: 4px;

      &.active {
        background-color: $grey;
      }
      &.done {
        background-color: $success;
      }
      &.error {
        background-color: $error;
      }
    }

    .ticket-popover {
      display: none;
      width: 740px;
      position: absolute;
      left: 0;
      bottom: 24px;
      background-color: $white;
      box-shadow: 0 13px 50px 0 rgba(96, 94, 111, 0.2);
      border-radius: 16px;
      padding: 20px;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $white;
        position: absolute;
        left: 22px;
        bottom: -10px;
      }
    }

    &:hover {
      a:not(.active) {
        transform: scaleY(2);
        transform-origin: bottom;
        transition: 0.1s all;
      }
      .ticket-popover {
        display: block;
      }
    }
    &.disabled {
      a {
        opacity: 0.5;
      }
    }
  }
}

.animation-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  & > svg {
    position: relative;
    width: 100%;
  }
}

.home-categories-section {
  min-height: 100vh;
}
.categories-wrapper {
  overflow: hidden;
  display: flex;
  box-shadow: 0 10px 60px -30px rgba(121, 126, 168, 0.08), inset -1px 0 0 0 #f3f4f7;
  background-color: #ffffff;
  border-radius: 10px 0 0 10px;
  .left-side {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 30px 45px;
    border-right: 1px solid #f4f5f8;
    background-color: #ffffff;
    border-radius: 10px 0 0 10px;
    span,
    svg {
      display: flex;
      height: 6px;
    }
    svg.icon-1 {
      width: 30px;
      path {
        fill: #a5b4bd;
        fill-rule: evenodd;
      }
    }
    svg.icon-2 {
      width: 12px;
      path {
        fill: rgba(24, 26, 38, 0.3);
        fill-rule: nonzero;
      }
    }
  }
  .overflow {
    overflow-x: scroll;
    .categories-swipe {
      position: relative;
      display: flex;
      .swipe-block {
        display: flex;
        flex-direction: column;
        width: 570px;
        min-height: 490px;
        padding: 40px 60px;
        flex-shrink: 0;
        border-right: 1px solid #f4f5f8;
        &:last-child {
          border-right: none;
        }
        .head-block {
          display: flex;
          align-items: center;
          flex-grow: 1;
          p.caption {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            border-radius: 16px;
            box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
            background-color: #FD4100;
            font-family: TBCXMedium;
            font-size: 34px;
            color: #ffffff;
          }
          p.description {
            margin-top: 0;
            padding-left: 28px;
            font-family: TBCXMedium;
            font-size: 22px;
            line-height: 1.23;
            color: #181a26;
            span {
              color: #FD4100;
            }
          }
        }
        p.options {
          flex-grow: 1;
          align-items: center;
          font-family: TBCXRegular;
          font-size: 13px;
          line-height: 1.85;
          color: #181a26;
          padding: 36px 30px 40px 88px;
        }
        .image-block {
          flex-grow: 0;
          display: flex;
          height: 225px;
          align-items: center;
          justify-content: center;
          background-color: #f7f9fc;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media all and (max-height: 812px) {
  .driving-animation-section {
    margin-top: -75px;
  }
}
