.regulators {
  display: flex;
  .regulator-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 86px 0;
    .item {
      display: flex;
      flex-direction: column;
      padding-bottom: 140px;
      .overflow {
        display: flex;
        width: 100%;
        align-items: center;
      }
      .empty,
      .block,
      .slider-block {
        display: flex;
        width: 50%;
      }
      .block {
        flex-shrink: 0;
        flex-grow: 1;
        position: relative;
        .wrapt {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          .shift {
            width: 801px;
            padding-right: 51px;
            display: flex;
            flex-direction: column;
            .head {
              display: flex;
              align-items: center;
              padding-bottom: 47px;
              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90px;
                height: 90px;
                border-radius: 28px;
                box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
                background-color: #FD4100;
                font-family: TBCXBold;
                font-size: 40px;
                color: #ffffff;
                font-style: normal;
              }
              p {
                display: flex;
                flex-direction: column;
                font-family: TBCXMedium;
                font-size: 40px;
                line-height: 1.2;
                padding-left: 30px;
                span:first-child {
                  color: #181a26;
                }
                span:last-child:not(:only-child) {
                  color: #637279;
                }
              }
            }
            .rules {
              display: flex;
              flex-direction: column;
              .rule {
                display: flex;
                &.r-false {
                  .icon i {
                    background-color: #ff5b3a;
                    box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
                    background-size: 7px 7px;
                    background-image: url('../../svgs/error.svg');
                  }
                  .text p {
                    color: #ff5b3a;
                  }
                }
                &.r-true {
                  .icon i {
                    box-shadow: 0 10px 30px 0 rgba(41, 177, 78, 0.3);
                    background-color: #29b14e;
                    background-size: 9px 7px;
                    background-image: url('../../svgs/done.svg');
                  }
                  .text p {
                    color: #29b14e;
                  }
                }
                .icon {
                  padding: 27px 35px;
                  i {
                    display: flex;
                    width: 19px;
                    height: 19px;
                    border-radius: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }
                }
                .text {
                  margin: 0 30px;
                  padding: 25px 50px 18px 0;
                  border-bottom: 1px solid rgba($color: #181a26, $alpha: 0.08);
                  p {
                    font-size: 15px;
                    line-height: 1.6;
                    font-family: TBCXMedium;
                  }
                  span {
                    display: block;
                    font-size: 15px;
                    line-height: 1.47;
                    color: #181a26;
                    padding: 8px 0;
                  }
                }
              }
            }
          }
        }
      }
      .slider-block {
        display: flex;
        .slider {
          display: flex;
          align-items: center;
          width: 100%;
          height: 480px;
          border-radius: 28px 0 0 28px;
          background-color: rgba($color: #f8f7f6, $alpha: 0.6);
          .slick-list {
            width: 100%;
            height: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
          }
          .slide {
            outline: none;
            img {
              height: 480px;
              margin: auto;
            }
          }
          .slick-prev,
          .slick-next {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: 60px;
            height: 60px;
            background-color: $white;
            border-radius: 100%;
            box-shadow: 10px 10px 34px 0 rgba(96, 94, 111, 0.08);
            transition: all 0.3s;
            &:hover {
              background: $orange;
              color: $white;
              box-shadow: 0 9px 24px 0 rgba(255, 146, 32, 0.2);
            }
            &.slick-disabled {
              display: none !important;
            }
          }
          .slick-prev:before,
          .slick-next:before {
            content: '';
            font-family: 'slick';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            width: 6px;
            height: 10px;
            opacity: 1;
          }
          .slick-prev:before {
            background-image: url(../../svgs/nav-prev.svg);
          }

          .slick-next:before {
            background-image: url(../../svgs/nav-next.svg);
          }
          .slick-prev {
            left: 50px;
            &:hover:before {
              background-image: url(../../svgs/nav-prev-hover.svg);
            }
          }
          .slick-next {
            right: 50px;
            &:hover:before {
              background-image: url(../../svgs/nav-next-hover.svg);
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1600px) {
  .regulators .regulator-list .item .block {
    padding-left: 20px;
  }
}

@media all and (max-width: 1300px) {
  .regulators .regulator-list .item .overflow {
    flex-direction: column;
  }
  .regulators .regulator-list .item .block {
    width: 100%;
    padding: 0 20px;
  }
  .regulators .regulator-list .item .block .wrapt .shift {
    width: 100%;
    padding: 0;
  }
  .regulators .regulator-list .item .slider-block {
    width: 100%;
    padding-top: 30px;
  }
  .regulators .regulator-list .item .slider-block .slider {
    height: 500px;
    border-radius: 0;
  }
}

@media all and (max-width: 800px) {
  .regulators .regulator-list .item .slider-block .slider .slide img {
    height: 400px;
  }
}

@media all and (max-width: 600px) {
  .regulators .regulator-list .item .block .wrapt .shift .head i {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    font-size: 40px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .head p {
    font-size: 30px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .icon {
    display: flex;
    align-items: center;
    padding: 25px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 15px;
    padding: 25px 0 18px 0;
  }
}
@media all and (max-width: 500px) {
  .regulators .regulator-list .item .block .wrapt .shift .head i {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    font-size: 30px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .head p {
    font-size: 20px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .icon {
    padding: 15px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 10px;
    padding: 18px 0;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text p {
    font-size: 14px;
    line-height: 1.3;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text span {
    font-size: 13px;
    line-height: 1.3;
  }
  .regulators .regulator-list .item .slider-block .slider {
    height: 400px;
  }
  .regulators .regulator-list .item .slider-block .slider .slide img {
    height: 300px;
  }
}

@media all and (max-width: 500px) {
  .regulators .regulator-list .item .block .wrapt .shift .head {
    padding-bottom: 30px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .head i {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    font-size: 26px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .head p {
    font-size: 18px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .icon {
    padding: 10px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 5px;
    padding: 15px 0;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text p {
    font-size: 13px;
  }
  .regulators .regulator-list .item .block .wrapt .shift .rules .rule .text span {
    font-size: 12px;
    line-height: 1.6;
  }
  .regulators .regulator-list .item .slider-block .slider {
    height: 350px;
  }
  .regulators .regulator-list .item .slider-block .slider .slide img {
    height: 250px;
  }
  .regulators .regulator-list .item .slider-block .slider .slick-prev,
  .regulators .regulator-list .item .slider-block .slider .slick-next {
    width: 40px;
    height: 40px;
  }
  .regulators .regulator-list .item .slider-block .slider .slick-prev {
    left: 25px;
  }
  .regulators .regulator-list .item .slider-block .slider .slick-next {
    right: 25px;
  }
}
