svg.license-i {
  polygon,
  path {
    fill: #a5b4bd;
    fill-rule: nonzero;
    opacity: 0.8;
    transition: fill 0.2s ease;
  }
  &.active {
    polygon,
    path {
      fill: #FD4100;
      opacity: 1;
    }
  }
  &.a-cat-i {
    width: 30px;
    height: 20px;
  }
  &.b-cat-i {
    width: 34px;
    height: 14px;
  }
  &.c-cat-i {
    width: 34px;
    height: 15px;
  }
  &.c1-cat-i {
    width: 34px;
    height: 17px;
  }
  &.d-cat-i {
    width: 32px;
    height: 16px;
  }
  &.d1-cat-i {
    width: 34px;
    height: 14px;
  }
  &.ts-cat-i {
    width: 24px;
    height: 20px;
  }
  &.s-cat-i {
    width: 36px;
    height: 20px;
  }
  &.bul-i {
    width: 32px;
    height: 20px;
  }
  &.tram-cat-i {
    width: 44px;
    height: 22px;
  }
  &.mil-cat-i {
    width: 34px;
    height: 10px;
  }
}
