.signs-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  .sign-item {
    cursor: pointer;
    padding: 40px;
    margin-bottom: 30px;
    text-align: center;
    .sign-img {
      margin-bottom: 40px;
      img {
        max-height: 90px;
        width: auto;
      }
    }
    .sign-title {
      font-size: 12px;
      line-height: 16px;
      max-width: 236px;
      margin: 0 auto;
    }
  }
}

@mixin modal() {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  overflow: hidden;
}

.rs-modal {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9999;
  opacity: 0;
  padding-top: 5%;
  background-color: rgba($color: #ffffff, $alpha: 0.3);
  .rs-modal-container {
    position: relative;

    span.rs-modal-close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
      border: none;
      border-radius: 100%;
      width: 34px;
      height: 34px;
      transition: background-color 0.2s ease;
      cursor: pointer;
      svg {
        display: block;
        width: 10px;
        height: 10px;
        path {
          fill: rgba($color: #181d26, $alpha: 0.9);
        }
      }
      &:hover {
        background-color: #FD4100;
      }
    }
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 640px;
    border-radius: 30px;
    box-shadow: 10px 20px 100px 0 rgba(92, 87, 122, 0.2);
    background-color: #ffffff;
    padding: 40px 55px 55px 55px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 150px;
      // object-fit: cover;
      margin: auto;
    }
    p {
      font-family: TBCXMedium;
      font-size: 16px;
      line-height: 1.38;
      color: #181a26;
      padding: 30px 80px 34px 80px;
    }
    span.description {
      max-width: 520px;
      display: block;
      margin: auto;
      font-size: 13px;
      opacity: 0.9;
      line-height: 1.38;
      color: #181a26;
      padding-bottom: 49px;
    }
    button {
      display: flex;
      align-self: center;
      background-color: transparent;
      border: none;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: #637279;
          fill-rule: nonzero;
        }
      }
      span {
        display: block;
        font-size: 11px;
        letter-spacing: 0.79px;
        color: #181a26;
        padding-left: 12px;
      }
    }
  }
}

.rs-carousel-modal {
  @include modal();
  padding: 0 160px;
  background-color: rgba($color: #181d26, $alpha: 0.9);
  &:fullscreen {
    background-color: rgba($color: #ffffff, $alpha: 0.2);
  }
  button.prev,
  button.next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 120px;
    height: 120px;
    background-color: #181a26;
    border: none;
    text-indent: -9999px;
    border-radius: 100%;
    background-image: url('../../svgs/arrow.svg');
    background-repeat: no-repeat;
    background-size: 12px 18px;
    background-position: center left 26px;
    transition: opacity 0.2s ease;
    &.prev {
      left: -60px;
      transform: rotate(180deg);
    }
    &.next {
      right: -60px;
    }
    &.disabled {
      opacity: 0.5;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .rs-carousel-modal-container {
    position: relative;
    width: 100%;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 120px 0 38px 0;
    button.rs-carousel-modal-close {
      position: absolute;
      top: 34px;
      right: 34px;
      width: 34px;
      height: 34px;
      background-color: rgba($color: #a5b4bd, $alpha: 0.5);
      border-radius: 100%;
      text-indent: -9999px;
      transition: opacity 0.2s ease;
      background-image: url('../../svgs/close.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px 12px;
      border: none;
      &:active {
        border: none;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .carousel-1 {
      width: 100%;
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .slide {
        outline: none;
      }
      .overflow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          max-width: 80%;
          max-height: 220px;
          object-fit: cover;
        }
        p {
          font-family: TBCXMedium;
          font-size: 18px;
          line-height: 1.22;
          color: #181a26;
          padding: 60px 0 42px 0;
          text-align: center;
        }
        span {
          max-width: 700px;
          font-size: 15px;
          line-height: 1.53;
          color: #181a26;
        }
      }
    }
    .carousel-2 {
      width: 100%;
      padding: 166px 100px 0 100px;
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .slick-current {
        .overflow {
          padding-top: 0;
          opacity: 1;
        }
      }
      .slide {
        outline: none;
        cursor: pointer;
      }
      .overflow {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0.6;
        transition: opacity 0.2s ease, padding 0.2s ease;
        img {
          max-width: 80px;
          height: 70px;
          object-fit: cover;
        }
        p {
          text-align: center;
          font-size: 11px;
          line-height: 1.45;
          color: #181a26;
          padding: 20px 50px 0 50px;
        }
      }
    }
  }
}

button.rs-start-quest-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 60px;
  height: 60px;
  box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
  border-radius: 100%;
  border: none;
  background-color: #FD4100;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 19px;
    height: 19px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 5px -3px 5px rgba($color: #a5a5a5, $alpha: 0.1);
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    svg {
      display: block;
      width: 7px;
      height: 7px;
      path {
        fill: #637279;
        transition: fill 0.2s ease;
      }
    }
    &:hover {
      background-color: #ff5b3a;
      svg path {
        fill: #fff;
      }
    }
  }
  &:hover i {
    opacity: 1;
  }
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.rs-quest-modal .rs-quest-modal-container {
  .rs-quest-modal-view {
    &.fillground {
      .rs-quest-answer-box .rs-quest-answer {
        button,
        .rs-quest-empty {
          opacity: 0.2;
        }
        button {
          pointer-events: none;
        }
      }
    }
    .rs-quest-answer-box-empty {
      justify-content: space-between;
      .rs-quest-answer {
        &:first-child {
          margin-right: 0 !important;
        }
        &:last-child {
          margin-left: 0 !important;
        }
      }
    }
    .rs-quest-answer-box {
      display: flex;
      order: 3;
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 2;
        .rs-quest-answer {
          margin: 10px 0;
          &:first-child {
            margin: 10px 20px;
          }
          &:last-child {
            margin: 10px 20px;
          }
        }
      }
      .rs-quest-answer {
        display: flex;
        align-items: center;
        margin: 10px 20px;
        border-radius: 20px;
        p {
          font-size: 18px;
          line-height: 1.78;
          color: #181a26;
          text-align: center;
        }
        p.wrong-type span {
          display: block;
        }
        button,
        .rs-quest-empty {
          width: 160px;
          height: 160px;
          background-color: #fafafa;
          border-radius: 20px;
          transition: opacity 0.2s ease;
        }
        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: none;
          img {
            display: block;
            width: 80px;
          }
          i {
            font-size: 26px;
            color: #ffffff;
            font-style: normal;
            padding-top: 55px;
            display: none;
          }
        }
        span.happy-emoji {
          display: none;
          font-size: 40px;
          padding-top: 80px;
        }
        &.false,
        &.true {
          button {
            opacity: 1;
          }
        }
        &.false button {
          background-color: #ff5b3a;
          box-shadow: 0 18px 40px -12px rgba(255, 91, 58, 0.35);
        }
        &.true button {
          background-color: #29b14e;
          box-shadow: 0 18px 40px -12px rgba(41, 177, 78, 0.35);
        }
        &.correct {
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          border-radius: 28px;
          margin: 0;
          background-color: #fff;
          padding-top: 60px;
          button {
            width: auto;
            height: auto;
            pointer-events: none;
            box-shadow: 0 34px 44px -12px rgba(41, 177, 78, 0.35);
            background-color: #29b14e;
            padding: 84px 84px 53px 84px;
            transition: background-color 0.2s ease, box-shadow 0.2s ease;
            img {
              width: 100px;
            }
            i {
              display: block;
            }
          }
          span.happy-emoji {
            display: block;
          }
        }
      }
    }
  }
}

.rs-quest-btn-modal .rs-quest-btn-modal-container .rs-quest-btn-modal-view {
  width: 580px;
  .no-longer-show-box {
    display: flex;
    flex-direction: column;
    padding: 49px 40px 10px 40px;
    .wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 71px;
      input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      label {
        cursor: pointer;
      }
      label.checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 6px;
        background-color: #FD4100;
        i {
          display: block;
          width: 8px;
          height: 6px;
          background-image: url('../../svgs/check.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 8px 6px;
          opacity: 0.4;
          transition: opacity 0.2s ease;
        }
      }
      label.text {
        font-size: 14px;
        color: #181a26;
        margin-left: 15px;
      }
      input:checked + label.checkbox i {
        opacity: 1;
      }
    }
    button {
      height: 80px;
      border-radius: 16px;
      box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
      background-color: #FD4100;
      font-family: TBCXMedium;
      font-size: 22px;
      color: #ffffff;
      border: none;
      transition: border-radius 0.2s ease;
      &:hover {
        border-radius: 35px;
      }
    }
  }
}

@media all and (max-width: 1920px) and (min-width: 1100px) {
  .rs-carousel-modal {
    padding: 0 vw1920(160px);
    button.prev,
    button.next {
      width: vw1920(120px);
      height: vw1920(120px);
      background-size: vw1920(12px) vw1920(18px);
      background-position: center left vw1920(26px);
      &.prev {
        left: vw1920(-60px);
      }
      &.next {
        right: vw1920(-60px);
      }
    }
    .rs-carousel-modal-container {
      border-radius: vw1920(20px);
      padding: vw1920(120px) 0 vw1920(38px) 0;
      button.rs-carousel-modal-close {
        top: vw1920(34px);
        right: vw1920(34px);
        width: vw1920(34px);
        height: vw1920(34px);
        background-size: vw1920(12px) vw1920(12px);
      }
      .carousel-1 {
        .overflow {
          img {
            max-height: vw1920(220px);
          }
          p {
            font-size: vw1920(18px);
            padding: vw1920(60px) 0 vw1920(42px) 0;
          }
          span {
            max-width: vw1920(700px);
            font-size: vw1920(15px);
          }
        }
      }
      .carousel-2 {
        padding: vw1920(166px) vw1920(100px) 0 vw1920(100px);
        .overflow {
          padding-top: vw1920(20px);
          img {
            max-width: vw1920(80px);
            height: vw1920(70px);
          }
          p {
            font-size: vw1920(11px);
            padding: vw1920(20px) vw1920(50px) 0 vw1920(50px);
          }
        }
      }
    }
  }
}

@media all and (max-width: 1100px - 1px) {
  .rs-carousel-modal .rs-carousel-modal-container .carousel-1 {
    .slick-track {
      display: flex;
      align-items: center;
    }
    .overflow span {
      max-width: 100%;
      padding: 0 30px;
    }
  }
  .rs-carousel-modal {
    padding: 0 100px;
  }

  .rs-carousel-modal .rs-carousel-modal-container .carousel-2 {
    display: none;
  }
}
@media all and (max-width: 800px) {
  .rs-carousel-modal {
    padding: 0;
  }
  .rs-carousel-modal .rs-carousel-modal-container {
    border-radius: 0;
  }
  .rs-carousel-modal button.prev,
  .rs-carousel-modal button.next {
    display: none;
  }
}

@media all and (max-width: 1000px) {
  .rs-modal .rs-modal-container button {
    display: none;
  }
}

@media all and (max-width: 640px) {
  .rs-modal .rs-modal-container {
    padding: 40px 20px 35px 20px;
  }
  .rs-carousel-modal .rs-carousel-modal-container {
    padding: 50px 0 38px 0;
  }
  .rs-carousel-modal .rs-carousel-modal-container .carousel-1 .overflow p {
    font-size: 16px;
  }
  .rs-carousel-modal .rs-carousel-modal-container .carousel-1 .overflow span {
    font-size: 13px;
  }
  .rs-carousel-modal .rs-carousel-modal-container .carousel-1 .overflow img {
    max-height: 180px;
  }
}
