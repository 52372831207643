@import 'components/variables', 'components/mixins', 'components/fonts', 'icons';

*::-webkit-scrollbar {
  width: 0;
}

* {
  transition: opacity 0.2s ease, background-color 0.2s ease, fill 0.2s ease,
    color 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease,
    border-radius 0.2s ease;
}

.h-100,
html,
body,
#root {
  height: 100%;
}

body {
  background-color: $white;
  font-family: TBCXRegular;
  color: $dark;
  &.driving-exam {
    padding-top: 25px;
  }
  &.exam-start {
    min-height: 100vh;
    background-color: #f4f5f6;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

main {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 6;
  padding-top: 120px;
  &.w-exam-started {
    padding-top: 0;
  }
}

@media all and (max-height: 812px) {
  .wrapper {
    padding-top: 75px;
  }
}

@import 'components/modal-manager', 'components/bread-crumb', 'components/ticket',
  'components/driving', 'components/header', 'components/footer',
  'components/driving-schools', 'components/signs', 'components/main-anim';

@import 'modules/c-input-v1', 'modules/c-select-v1', 'modules/c-select-v2',
  'modules/profile-modal', 'modules/profile', 'modules/tips', 'modules/regulator',
  'modules/categories', 'modules/exam';

@import 'responsive';
