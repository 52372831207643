@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }

  & {
    *zoom: 1;
  }
}

@function vw980($target) {
  $vw-context: (980 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

@function vw1920($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
