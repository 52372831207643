.driving-schools {
  position: relative;
  display: flex;
  .rubber-1,
  .rubber-2 {
    display: flex;
    width: 50%;
    height: calc(100vh - 120px);
    transition: width 0.2s ease;
    .rating-stars {
      padding: 4px 9px 3px 9px;
      border-radius: 6px;
      background-color: #FD4100;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 9px;
        height: 9px;
        fill: #fff;
        fill-rule: evenodd;
      }
      p {
        font-size: 16px;
        color: #ffffff;
        padding-left: 6px;
      }
    }
  }
  .rubber-1 {
    flex-shrink: 0;
    flex-grow: 1;
    position: relative;
    i.figure {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      height: 100px;
      display: block;
      pointer-events: none;
    }
    i.figure-top {
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    i.figure-bottom {
      bottom: 0;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .wrapt {
      display: flex;
      width: 100%;
      overflow-y: auto;
      justify-content: flex-end;
      .fifty {
        width: 801px;
        padding-right: 51px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        transition: width 0.2s ease;
        .head {
          display: flex;
          max-width: 750px;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 26px 0 26px 8px;
          .bread-crumbs {
            padding: 0;
            .container {
              width: auto;
            }
          }
        }
        .school-list {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 40px;
          .item {
            cursor: pointer;
            width: 100%;
            max-width: 750px;
            flex-shrink: 0;
            margin-bottom: 24px;
            border-radius: 12px;
            border-style: solid;
            border-width: 1px;
            border-image-source: linear-gradient(to right, #ffffff, #ebebeb 100%);
            border-image-slice: 1;
            background-image: linear-gradient(to bottom, #ffffff, #ffffff),
              linear-gradient(to right, #ffffff, #ebebeb 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            transition: box-shadow 0.2s ease, transform 0.2s ease;
            &:hover {
              box-shadow: 20px 15px 20px 0 rgba(121, 126, 168, 0.05);
              transform: scale(1.03);
            }
            .wrap {
              display: flex;
              padding: 19px 0 13px 0;
              .image {
                width: 170px;
                height: 130px;
                flex-shrink: 0;
                position: relative;
                border-radius: 10px;
                background-color: #f4f5f6;
                background-image: url('../../svgs/no-school-photo.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 65%;
                .rating-stars {
                  position: absolute;
                  top: 12px;
                  left: 12px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 10px;
                }
              }
              .content {
                width: 100%;
                flex-shrink: 1;
                display: flex;
                flex-direction: column;
                padding: 0 40px;
                .top {
                  display: flex;
                  justify-content: space-between;
                  // flex-direction: column;
                  border-bottom: 1px solid #ebebeb;
                  padding-bottom: 13px;
                  p.address {
                    font-size: 17px;
                    padding-bottom: 6px;
                    color: rgba($color: #181a26, $alpha: 0.9);
                  }
                  p.title {
                    font-size: 14px;
                    color: #FD4100;
                  }
                }
                .bottom {
                  display: flex;
                  padding-top: 13px;
                  .l,
                  .r {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                  }
                  .r {
                    align-items: flex-end;
                  }
                  .opt {
                    display: flex;
                    align-items: center;
                    padding: 7px 0;
                    img {
                      width: 14px;
                      height: 14px;
                    }
                    a,
                    p {
                      line-height: 100%;
                      padding-left: 15px;
                    }
                    &.license p {
                      font-family: TBCXMedium;
                      font-size: 14px;
                      color: #181a26;
                      font-style: normal;
                      letter-spacing: 3px;
                      padding-left: 19px;
                      text-transform: uppercase;
                      line-height: 1.3;
                    }
                    &.phone p {
                      font-size: 16px;
                      letter-spacing: 0.69px;
                      color: #637279;
                    }
                    &.time p {
                      font-size: 13px;
                      color: #637279;
                      b {
                        font-family: TBCXMedium;
                      }
                    }
                    &.www a {
                      font-size: 12px;
                      color: #637279;
                      transform: opacity 0.2s ease;
                      &:hover {
                        opacity: 0.8;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .rubber-2 {
    position: relative;
    overflow: hidden;
    .school-display {
      display: flex;
      position: absolute;
      top: 0;
      left: 10px;
      bottom: 0;
      margin: auto;
      width: 520px;
      height: calc(100% - 20px);
      border-radius: 12px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      z-index: 6;
      .view {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        border-radius: 12px;
        margin: 20px 0;
        overflow: auto;
        .image {
          position: relative;
          background-color: #f4f5f6;
          background-image: url('../../svgs/no-school-photo.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 35%;
          height: 170px;
          border-radius: 12px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            object-fit: cover;
          }
          button {
            position: absolute;
            top: 12px;
            right: 12px;
            background-color: transparent;
            border: none;
            transition: opacity 0.2s ease;
            box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);
            border-radius: 100%;
            flex-shrink: 0;
            background-color: rgba($color: #000000, $alpha: 0.3);
            padding: 0;
            &:hover {
              opacity: 0.9;
            }
            img {
              width: 34px;
              height: 34px;
            }
          }
        }
        .caption {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 23px;
          .text {
            padding-left: 2px;
            p {
              font-family: TBCXMedium;
              color: #FD4100;
              font-size: 20px;
            }
            span {
              opacity: 0.9;
              font-size: 14px;
              color: rgba($color: #181a26, $alpha: 0.9);
            }
          }
          .rating-stars {
            display: flex;
            padding: 7px 18px 8px 17px;
            svg {
              width: 14px;
              height: 14px;
            }
            p {
              font-size: 22px;
              padding-left: 12px;
            }
          }
        }
        .info-www {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 100%;
          background-color: rgba($color: #a5b4bd, $alpha: 0.1);
          border-radius: 6px;
          padding: 24px 20px 22px 20px;
          margin: 21px 0 26px 0;
          .left {
            display: flex;
            img {
              width: 14px;
              height: 16px;
            }
            p {
              font-size: 14px;
              color: rgba($color: #181a26, $alpha: 0.8);
              margin-left: 18px;
              text-transform: uppercase;
            }
          }
          .right {
            display: flex;
            img {
              width: 16px;
              height: 16px;
            }
            a {
              display: flex;
              font-size: 12px;
              color: rgba($color: #181a26, $alpha: 0.8);
              margin-left: 10px;
              transition: opacity 0.2s ease;
              white-space: nowrap;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
        .phone {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
          }
          span {
            display: flex;
            font-size: 16px;
            letter-spacing: 0.69px;
            color: #181a26;
            padding-left: 13px;
          }
        }
        .schedule {
          display: flex;
          align-items: center;
          padding: 23px 0;
          &.is-open {
            align-items: flex-start;
            img.icon {
              margin-top: 2px;
            }
          }
          img.icon {
            width: 16px;
            height: 16px;
          }
          .wrap {
            width: 55%;
            display: flex;
            flex-direction: column;
            button.current {
              display: flex;
              align-items: center;
              background: transparent;
              align-self: flex-start;
              border: none;
              padding: 0;
              margin: 0 0 0 13px;
              p {
                display: flex;
                i {
                  display: flex;
                  font-size: 13px;
                  color: #ff5b3a;
                  font-style: normal;
                }
                b {
                  display: flex;
                  font-family: TBCXMedium;
                  font-size: 13px;
                  color: #181a26;
                  padding: 0 11px 0 7px;
                }
              }
              img {
                width: 10px;
                height: 6px;
              }
            }
            .schedule-list {
              display: flex;
              width: 100%;
              flex-direction: column;
              margin: 0 0 0 13px;
              .item {
                display: flex;
                width: 100%;
                padding: 3px 0;
                &:first-child {
                  padding-top: 0;
                  p {
                    font-weight: bold;
                  }
                }
                &:last-child {
                  padding-bottom: 0;
                }
                p {
                  width: 50%;
                  white-space: nowrap;
                  font-size: 13px;
                  color: #181a26;
                  &:first-child {
                    text-transform: capitalize;
                  }
                  &:last-child {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                  }
                }
              }
            }
          }
        }
        .description {
          border-radius: 2px;
          border: 1px solid rgba(96, 94, 111, 0.13);
          border-left: none;
          border-right: none;
          padding: 25px 1px 32px 1px;
          p.title {
            font-family: TBCXMedium;
            font-size: 18px;
            color: rgba($color: #181a26, $alpha: 0.8);
            margin-bottom: 15px;
          }
          .editor {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            color: rgba($color: #181a26, $alpha: 0.8);
            ul {
              list-style: circle;
              padding-left: 20px;
              li {
                font-size: 12px;
              }
            }
          }
        }
        .reviews {
          line-height: 100%;
          .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 35px 0;
            .left {
              p {
                font-family: TBCXMedium;
                font-size: 18px;
                color: rgba($color: #181a26, $alpha: 0.8);
                padding-top: 2px;
              }
            }
            .right {
              display: flex;
              align-items: center;
              span {
                display: block;
                font-size: 11px;
                color: #637279;
                padding-right: 8px;
              }
              ul {
                display: flex;
                li {
                  margin-left: 8px;
                  cursor: pointer;
                  &[data-events='disabled'] {
                    pointer-events: none;
                    cursor: default;
                  }
                  svg {
                    width: 18px;
                    height: 18px;
                    path {
                      fill: none;
                      fill-rule: nonzero;
                      stroke: #637279;
                    }
                  }
                  &:hover,
                  &.starred {
                    svg path {
                      fill: #FD4100;
                      stroke: #FD4100;
                    }
                  }
                }
              }
            }
          }
          .list {
            display: flex;
            flex-direction: column;
            .item {
              display: flex;
              border-radius: 12px;
              border-style: solid;
              border-width: 1px;
              border-image-source: linear-gradient(
                to right,
                #ffffff -15%,
                #ebebeb 100%
              );
              border-image-slice: 1;
              background-image: linear-gradient(to bottom, #ffffff, #ffffff),
                linear-gradient(to right, #ffffff -15%, #ebebeb 100%);
              background-origin: border-box;
              background-clip: content-box, border-box;
              margin-bottom: 14px;
              .wrap {
                display: flex;
                width: 100%;
                padding: 10px 20px 10px 0;
                .avatar {
                  margin-left: -1px;
                  flex-shrink: 0;
                  svg,
                  img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 100%;
                  }
                }
                .cmt-info {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  padding: 3px 0 8px 20px;
                  p {
                    font-family: TBCXMedium;
                    font-size: 15px;
                    color: #181a26;
                  }
                  .stars {
                    display: flex;
                    padding: 7px 0 10px 0;
                    ul {
                      display: flex;
                      li {
                        margin-right: 3px;
                        &:last-child {
                          margin-right: 0;
                        }
                        &.starred {
                          svg {
                            path {
                              fill: #FD4100;
                              stroke: #FD4100;
                            }
                          }
                        }
                        svg {
                          width: 12px;
                          height: 12px;
                          path {
                            fill: #ffdab2;
                            fill-rule: nonzero;
                            stroke: #ffdab2;
                          }
                        }
                      }
                    }
                    em {
                      font-size: 13px;
                      line-height: 1.62;
                      color: #181a26;
                      padding-left: 12px;
                      font-style: normal;
                    }
                  }
                  span {
                    font-size: 13px;
                    line-height: 1.62;
                    color: #181a26;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }
                }
              }
            }
            p.no-review {
              text-align: center;
              padding: 30px 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
    button.resize-rubber {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
      height: 268px;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: auto;
      img {
        width: 35px;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    & > div:not(.school-display) {
      width: 100% !important;
      height: 100% !important;
    }
    .z-hover {
      z-index: 5 !important;
    }
    .mapboxgl-marker {
      width: 12px;
      height: 12px;
    }
    .info {
      position: absolute;
      .block {
        position: absolute;
        z-index: 1;
        transform: translate(-50%, 0%);
        bottom: 100%;
        left: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 102px;
        border-radius: 30px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        margin-bottom: 12px;
        padding: 17px 15px;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid #ffffff;
        }
        .top {
          display: flex;
          svg {
            width: 14px;
            height: 14px;
            path {
              fill: #a5b4bd;
              fill-rule: nonzero;
            }
          }
          span {
            display: block;
            font-size: 13px;
            color: #181a26;
            padding-left: 13px;
            line-height: 100%;
            white-space: nowrap;
            text-transform: uppercase;
          }
        }
        .botton {
          display: flex;
          padding-top: 23px;
          svg {
            width: 14px;
            height: 14px;
            path {
              fill: #fff;
            }
          }
          p {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #ffffff;
            line-height: 100%;
            margin-top: 1px;
            padding-left: 21px;
            white-space: nowrap;
            b {
              font-family: TBCXBold;
            }
            span {
              display: block;
              margin-bottom: 8px;
            }
          }
        }
      }
      &.info-hover {
        z-index: 2;
        .block {
          min-width: 234px;
          align-items: flex-start;
          border-radius: 24px;
          box-shadow: 0 9px 24px 0 rgba(255, 146, 32, 0.3);
          background-color: #f48919;
          padding: 26px 22px 23px 22px;
          transform: translate(-50%, 0%);
          &::after {
            border-top-color: #f48919;
          }
          .top {
            svg {
              margin-top: 2px;
              path {
                fill: #ffffff;
              }
            }
            span {
              color: #ffffff;
              letter-spacing: normal;
              padding-left: 21px;
            }
          }
        }
      }
    }
    i.marker {
      display: flex;
      width: 12px;
      height: 12px;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      border-radius: 100%;
      transition: background-color 0.5s ease, box-shadow 0.5s ease,
        border-width 0.5s ease;
      em {
        display: flex;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #FD4100;
      }
      &.marker-hover {
        width: 68px;
        height: 68px;
        border-width: 28px;
        background-color: rgba(255, 146, 32, 0.35);
        box-shadow: none;
        transform: translate(-28px, -28px);
        em {
          width: 8px;
          height: 8px;
        }
      }
    }
    .mapboxgl-map canvas:focus {
      outline: none !important;
    }
  }
  &.revert {
    .rubber-1 {
      width: 92%;
      .wrapt {
        .fifty {
          width: 1607px;
          .school-list {
            .item:nth-child(2n - 1) {
              margin-right: 55px;
            }
          }
        }
      }
    }
    .rubber-2 {
      border-left: 1px none;
      button.resize-rubber {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        left: auto;
        right: 0;
        height: auto;
        width: 100%;
        background-color: rgba($color: #181a26, $alpha: 0.6);
        transform: rotate(180deg);
      }
    }
  }
}

.swoop {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 0 0 10px;
  .swoop-up {
    display: flex;
    img {
      width: 66px;
      height: 24px;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      padding: 0 9px;
      border-radius: 11px;
      background-color: #ff3b30;
      font-family: TBCXRegular;
      font-size: 12px;
      color: #fff;
      margin-left: 4px;
    }
  }
  .swoop-down {
    display: flex;
    align-items: center;
    padding-top: 4px;
    p {
      font-family: TBCXBold;
      font-size: 16px;
      color: rgba($color: #181a26, $alpha: 0.9);
      margin-right: 8px;
    }
    span {
      font-family: TBCXRegular;
      font-size: 12px;
      color: rgba($color: #637279, $alpha: 0.9);
      text-decoration: line-through;
    }
  }
}

@media all and (max-width: 1920px) {
  .driving-schools.revert .rubber-1 .wrapt .fifty {
    // width: vw1920(1607px);
    width: calc(100% - 100px);
  }
  .driving-schools.revert .rubber-1 .wrapt .fifty .head {
    max-width: 100%;
  }
  .driving-schools.revert .rubber-1 .wrapt .fifty .school-list {
    flex-wrap: wrap;
    .item {
      flex-shrink: 1;
    }
  }
}

@media all and (max-width: 1700px) {
  .driving-schools.invert {
    .rubber-1 .wrapt .fifty {
      width: 100%;
      align-items: flex-end;
      padding: 0 30px;
    }
    .rubber-1 .wrapt .fifty .head .select-type-1 {
      width: 150px;
    }
    .rubber-1 .wrapt .fifty .school-list {
      justify-content: flex-end;
    }
    .rubber-1 .wrapt .fifty .school-list .item {
      max-width: 750px;
      width: 100%;
    }
    .rubber-1 .wrapt .fifty {
      padding-left: 20px;
    }
  }
}

@media all and (max-width: 1600px) and (min-width: 1000px) {
  .driving-schools.revert {
    .rubber-1 .wrapt .fifty {
      width: 100%;
      padding-left: 20px;
      .school-list .item .wrap {
        flex-direction: column;
        align-items: center;
        .content {
          padding-top: 25px;
        }
      }
    }
    .rubber-1 .wrapt .fifty .head {
      padding: 26px 0 26px 0;
    }
  }
}

@media all and (max-width: 1500px) and (min-width: 1000px) {
  .driving-schools.invert {
    .rubber-1 .wrapt .fifty {
      padding-right: 30px;
      .school-list .item .wrap .content .bottom {
        flex-direction: column;
        .l,
        .r {
          width: 100%;
        }
        .r {
          align-items: flex-start;
        }
      }
    }
  }
}

@media all and (max-width: 1400px) and (min-width: 1000px) {
  .driving-schools.invert {
    .rubber-1 .wrapt .fifty .school-list .item .wrap {
      flex-direction: column;
      align-items: center;
    }
    .rubber-1 .wrapt .fifty .school-list .item .wrap .content {
      padding: 50px 40px 0 40px;
    }
  }
}

@media all and (max-width: 1300px) and (min-width: 1000px) {
  .driving-schools.revert {
    .rubber-1 .wrapt .fifty {
      .school-list .item .wrap {
        flex-direction: column;
        align-items: center;
        .content {
          padding: 20px 20px 0 20px;
          .bottom {
            flex-direction: column;
            .l,
            .r {
              width: 100%;
            }
            .r {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .driving-schools .rubber-2 {
    display: none;
  }
  .driving-schools .rubber-1 .wrapt {
    justify-content: flex-start;
    .fifty {
      padding-right: 20px;
    }
    .fifty .school-list .item {
      pointer-events: none;
    }
    .fifty,
    .fifty .school-list .item,
    .fifty .head {
      max-width: 100%;
    }
    .fifty .head {
      padding: 26px 0;
    }
  }
}

@media all and (max-width: 767px) {
  .driving-schools .rubber-1 .wrapt .fifty .head {
    justify-content: flex-end;
  }
}

@media all and (max-width: 700px) {
  .driving-schools .rubber-1 .wrapt .fifty .school-list .item .wrap {
    flex-direction: column;
    align-items: center;
    .content {
      padding: 40px 20px 0 20px;
    }
  }
}

@media all and (max-width: 600px) {
  .driving-schools
    .rubber-1
    .wrapt
    .fifty
    .school-list
    .item
    .wrap
    .content
    .bottom {
    flex-direction: column;
    .l,
    .r {
      width: 100%;
    }
    .r {
      align-items: inherit;
    }
  }
}

@media all and (max-width: 500px) {
  .driving-schools .rubber-1 .wrapt .fifty .school-list .item .wrap .content {
    padding: 20px 10px 0 0;
  }
}
