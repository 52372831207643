.driving-footer {
  background-color: transparent;
  border-top: 2px solid $orange;

  .footer-top {
    padding-left: 120px;
    position: relative;
    padding-top: 67px;
    @include clearfix();

    .footer-border-icon {
      display: inline-block;
      width: 90px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;

      svg {
        width: 18px;
        height: auto;
        path {
          fill: $grey-lighter;
        }
      }
    }

    .scroll-up {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 64px;
      height: 60px;
      background-color: rgba($orange, 0.1);
      border-radius: 20px;
      transition: all 0.3s;
      border: none;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: $orange;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    .footer-heading {
      font-size: 20px;
      font-family: TBCXMedium;
      margin-bottom: 30px;
    }

    .footer-list {
      display: inline-flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 120px;

      li {
        margin-right: 50px;
        button {
          background-color: transparent;
          margin: 0;
          padding: 0;
          border: none;
        }
        button,
        a {
          color: rgba($dark, 0.5);
          font-size: 13px;
          line-height: 30px;
          text-transform: capitalize;
        }
      }
    }

    .footer-social-list {
      li {
        display: inline-block;
        margin-right: 10px;

        .social-icon {
          display: block;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border: 1px solid #e4e7ed;
          position: relative;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &:hover {
            background-color: #e4e7ed;
          }
        }
      }
    }
  }
  .footer-bottom {
    padding: 80px 0 80px 120px;
    @include clearfix();

    .footer-copyright {
      float: left;
      font-size: 13px;
      color: rgba($dark, 0.5);
    }
    .top-ge {
      float: right;
    }
  }

  .footer-col {
    float: left;
    width: 25%;
  }

  .copyright {
    background-color: transparent;
  }
}
