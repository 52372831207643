.c-input-v1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  .overflow {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 29px;
    border: 1px solid #dadce0;
    position: relative;
    margin-bottom: 37px;
    transition: border-color 0.2s ease;
    input,
    i {
      display: flex;
      font-size: 15px;
    }
    input {
      width: 100%;
      height: 56px;
      border-radius: 29px;
      border: solid 1px transparent;
      background-color: #ffffff;
      padding: 0 30px;
      color: #181a26;
      transition: border-color 0.2s ease;
      &:disabled {
        opacity: 0.8;
      }
    }
    i {
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      font-style: normal;
      padding: 0 31px;
      color: #637279;
      pointer-events: none;
      transition: font-size 0.2s ease, top 0.2s ease, color 0.2s ease;
    }
    input[data-focused='true'] + i,
    input:focus + i {
      font-size: 12px;
      background-color: #ffffff;
      top: -10px;
      bottom: auto;
      padding: 0 6px;
      margin: 0 25px;
    }
    &[data-error='true'] {
      margin-bottom: 8px;
      border-color: #ff5b3a;
      input {
        border-color: #ff5b3a;
      }
      input[data-focused='true'] + i,
      input:focus + i {
        color: #ff5b3a;
      }
    }
  }
  span.report-error {
    display: flex;
    font-family: TBCXRegular;
    font-size: 12px;
    color: #ff5b3a;
    padding: 0 32px 11px 32px;
  }
}
