.exam-preparation {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f4f5f6;
  .gradient-top {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    padding: 10px 0 124px 0;
    h1 {
      font-family: TBCXMedium;
      font-size: 50px;
      color: #FD4100;
    }
  }
  .gradient-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .select-type-1 {
      width: 460px;
      margin-top: -40px;
      .my__control--menu-is-open,
      .my__control {
        min-height: 80px;
        z-index: 8;
        border-color: transparent;
        box-shadow: 0 6px 34px 0 rgba(96, 94, 111, 0.08);
        border-radius: 40px;
      }
      .my__control--menu-is-open {
        border-radius: 40px 40px 0 0;
      }
      .my__menu {
        border-color: transparent;
        box-shadow: none;
        z-index: 9;
        border-radius: 0 0 40px 40px;
      }
    }
    .exam-start-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      .caption {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 65px 0 26px 0;
        p {
          font-family: TBCXMedium;
          font-size: 13px;
          color: #181a26;
          padding-bottom: 11px;
        }
        span {
          display: flex;
          max-width: 220px;
          font-size: 12px;
          line-height: 14px;
          color: rgba($color: #181a26, $alpha: 0.6);
        }
      }
      .keys {
        display: flex;
        flex-direction: column;
        .num {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;
          span {
            margin: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 66px;
            border-radius: 16px;
            box-shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.06),
              inset 0 -6px 0 0 #e3e5e9;
            background-color: #ffffff;
            font-family: TBCXBold;
            font-size: 30px;
            color: #181a26;
            padding-bottom: 3px;
          }
        }
        span.space {
          display: flex;
          margin: 0 8px;
          justify-content: center;
          align-items: center;
          height: 70px;
          border-radius: 13px;
          box-shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.06), inset 0 -6px 0 0 #e3e5e9;
          background-color: #ffffff;
          font-size: 12px;
          color: #181a26;
          padding: 0 15px 7px 15px;
          b,
          i,
          em {
            display: flex;
          }
          b {
            font-family: TBCXMedium;
            font-size: 14px;
          }
          i {
            font-style: normal;
            font-size: 15px;
            padding: 0 5px;
          }
          em {
            font-style: normal;
          }
        }
      }
      .exam-at-checkbox-block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 0 60px 0;
        input {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }
        label {
          cursor: pointer;
        }
        label.checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          border-radius: 6px;
          background-color: #e3e5e9;
          i {
            display: block;
            width: 8px;
            height: 6px;
            background-image: url('../../svgs/check.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 8px 6px;
            opacity: 0;
            transition: opacity 0.2s ease;
          }
        }
        label.text {
          font-size: 12px;
          color: #181a26;
          margin-left: 15px;
        }
        input:checked + label.checkbox {
          background-color: #FD4100;
          i {
            opacity: 1;
          }
        }
      }
      button.start-exam {
        font-family: TBCXMedium;
        font-size: 18px;
        color: #ffffff;
        height: 80px;
        border-radius: 16px;
        box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
        background-color: #FD4100;
        border: none;
        transition: border-radius 0.2s ease;
        padding: 0 120px;
        &:hover {
          border-radius: 35px;
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .exam-preparation .gradient-top {
    padding: 50px 0 124px 0;
  }
}

@media all and (max-height: 1040px) {
  .exam-preparation .gradient-top {
    padding: 35px 0 80px 0;
  }
  .exam-preparation .gradient-bottom .exam-start-block .caption {
    padding: 0;
    justify-content: center;
  }
  .exam-preparation .gradient-bottom .exam-start-block .exam-at-checkbox-block {
    padding: 0;
  }
  .exam-preparation .gradient-bottom .exam-start-block {
    padding: 0 0 30px 0;
  }
}

@media all and (max-width: 980px) {
  .exam-preparation .gradient-bottom .exam-start-block {
    .exam-at-checkbox-block,
    .keys,
    .caption {
      display: none;
    }
  }
}

@media all and (max-width: 600px), (max-height: 767px) {
  .exam-preparation .gradient-top {
    padding: 30px 0 40px 0;
    h1 {
      font-size: 36px;
    }
  }
  .exam-preparation .gradient-bottom .select-type-1 {
    width: 100%;
    max-width: 460px;
  }
  .exam-preparation .gradient-bottom {
    padding: 0 10px;
  }
  .exam-preparation .gradient-bottom .exam-start-block button.start-exam {
    width: 100%;
    padding: 0 20px;
  }
}

@media all and (max-width: 500px), (max-height: 767px) {
  .exam-preparation .gradient-top h1 {
    font-size: 32px;
  }
  .exam-preparation .gradient-bottom .select-type-1 {
    margin-top: -30px;
    .my__control--menu-is-open,
    .my__control {
      min-height: 60px;
      box-shadow: 0 6px 24px 0 rgba(96, 94, 111, 0.08);
      border-radius: 30px;
    }
    .my__control--menu-is-open {
      border-radius: 30px 30px 0 0;
    }
    .my__menu {
      border-radius: 0 0 30px 30px;
    }
  }
  .exam-preparation .gradient-bottom .exam-start-block .caption p {
    font-size: 12px;
    padding-bottom: 8px;
  }
  .exam-preparation .gradient-bottom .exam-start-block .caption span {
    font-size: 11px;
  }
  .exam-preparation .gradient-bottom .exam-start-block .keys .num {
    padding-bottom: 15px;
  }
  .exam-preparation .gradient-bottom .exam-start-block .keys .num span {
    margin: 0 6px;
    width: 60px;
    height: 56px;
    border-radius: 10px;
    box-shadow: 0 15px 15px -12px rgba(0, 0, 0, 0.06), inset 0 -6px 0 0 #e3e5e9;
    font-size: 26px;
  }
  .exam-preparation .gradient-bottom .exam-start-block .keys span.space {
    margin: 0;
    height: 60px;
    border: 10px;
    box-shadow: 0 15px 15px -12px rgba(0, 0, 0, 0.06), inset 0 -6px 0 0 #e3e5e9;
    font-size: 11px;
    padding: 0 15px 3px 15px;
  }
  .exam-preparation
    .gradient-bottom
    .exam-start-block
    .exam-at-checkbox-block
    label.text {
    font-size: 11px;
  }
  .exam-preparation .gradient-bottom .exam-start-block button.start-exam {
    font-size: 16px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 18px 30px -12px rgba(255, 146, 32, 0.35);
    padding: 0 10px;
  }
}
