.profile-modal-view {
  padding: 45px 50px 50px 50px !important;
  p.caption {
    font-family: TBCXMedium;
    font-size: 36px;
    color: #181a26;
    padding: 0 10px;
    text-transform: capitalize;
  }
  .header-flow {
    display: flex;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background-color: rgba($color: #FD4100, $alpha: 0.1);
      border-radius: 100%;
      border: none;
      margin: 0 45px 0 11px;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 7px;
      }
    }
  }
  .form-container {
    display: flex;
    padding: 60px 0 0 0;
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .field {
      &.size > div:first-child {
        width: 200px;
        flex-shrink: 0;
      }
      display: flex;
      & > div {
        padding: 0 10px;
      }
      .submit-btn-block {
        display: flex;
        width: 100%;
        padding-top: 10px;
        button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          border-radius: 16px;
          background-color: #e3e5e9;
          border: none;
          font-size: 22px;
          color: #ffffff;
          font-family: TBCXMedium;
          text-transform: uppercase;
          transition: box-shadow 0.2s ease, background-color 0.2s ease;
          &:hover {
            box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
            background-color: #FD4100;
          }
          &:disabled {
            pointer-events: none;
          }
        }
      }
    }
    .field-password {
      position: relative;
      button {
        position: absolute;
        top: 18px;
        right: 25px;
        display: flex;
        font-size: 12px;
        color: #637279;
        background-color: transparent;
        border: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.progress-modal-view {
  width: 580px !important;
  padding: 0 0 60px 0 !important;
  img.background {
    width: 100%;
  }
  p {
    font-family: TBCXMedium;
    font-size: 40px;
    color: #FD4100;
    text-align: center;
    padding: 66px 10px 0 10px;
  }
  span {
    font-size: 19px;
    text-align: center;
    color: #637279;
    padding: 10px 10px 68px 10px;
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      margin: 0 9px;
      a {
        display: block;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media all and (max-width: 550px) {
  .profile-modal-view .form-container .field.size {
    flex-direction: column;
  }
  .profile-modal-view .form-container .field.size > div:first-child {
    width: 100%;
  }
  .profile-modal-view {
    padding: 45px 20px 50px 20px !important;
  }
  .profile-modal-view p.caption {
    font-size: 26px;
    line-height: 100%;
  }
  .profile-modal-view .form-container .field > div {
    padding: 0;
  }
  .profile-modal-view .form-container .field .submit-btn-block button {
    font-size: 18px;
    height: 60px;
  }
  .profile-modal-view .header-flow button {
    margin: 0 20px 0 0;
  }
}
