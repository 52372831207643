@mixin ticket-AQ {
  .question {
    .cover {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      border-radius: 14px;
      object,
      img {
        display: flex;
        width: 100%;
        border-radius: 14px;
      }
    }
    p.caption {
      font-family: TBCXBold;
      text-align: center;
      color: rgba($color: #181a26, $alpha: 0.9);
    }
  }
  .answers {
    display: flex;
    button {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 15px 0;
      span {
        display: flex;
        width: 50px;
        height: 50px;
        background-color: #a5b4bd;
        background-position: center center;
        background-repeat: no-repeat;
        font-family: TBCXBold;
        font-size: 22px;
        color: #ffffff;
        border-radius: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      em {
        display: flex;
        font-family: TBCXRegular;
        font-size: 14px;
        line-height: 1.43;
        color: rgba($color: #181a26, $alpha: 0.9);
        text-align: left;
        font-style: normal;
      }
      &.true,
      &.false {
        span {
          text-indent: -9999px;
        }
      }
      &.true {
        span {
          transform: scale(1.16);
          transform-origin: center center;
          border-radius: 18px;
          box-shadow: 0 10px 30px 0 rgba(41, 177, 78, 0.3);
          background-color: #29b14e !important;
          background-size: 20px 14px;
          background-image: url('../../svgs/done.svg');
        }
        em {
          font-family: TBCXBold;
          color: #29b14e !important;
          padding: 0 32px 0 18px;
        }
      }
      &.false {
        span {
          box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
          background-color: #ff5b3a !important;
          background-size: 14px 14px;
          background-image: url('../../svgs/error.svg');
        }
        em {
          color: #ff5b3a !important;
        }
      }
    }
    &.answered {
      button {
        span {
          background-color: rgba($color: #a5b4bd, $alpha: 0.4);
        }
        em {
          color: rgba($color: #181a26, $alpha: 0.4);
        }
      }
    }
  }
}

.tickets {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 14px 0 0 0;
  &.tickets-exam {
    padding: 0;
    .ticket {
      position: relative;
      z-index: 8;
    }
    .status-bar .right-side {
      justify-content: space-between;
    }
  }
  .ticket {
    flex: 1;
    max-width: 980px;
    width: 100%;
    padding: 0 20px 84px 20px;
    margin: 0 auto;
    @include ticket-AQ;
    .question {
      padding: 0 0 47px 0;
      .cover {
        height: 434px;
        justify-content: center;
        .no-photo {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 89px;
            height: 16px;
          }
          p {
            font-family: TBCXRegular;
            font-size: 13px;
            color: rgba($color: #181a26, $alpha: 0.4);
            padding-top: 20px;
          }
        }
      }
      p.caption {
        font-size: 15px;
        line-height: 1.47;
        letter-spacing: 0.4px;
        padding-top: 28px;
      }
      .q-desc-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: background-color 0.2s ease;
        button {
          position: absolute;
          top: 15px;
          right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 43px;
          height: 43px;
          background-color: #29b14e;
          border: 3px solid #ffffff;
          border-radius: 100%;
          transition: border-width 0.2s ease, opacity 0.2s ease;
          opacity: 0;
          svg.q-mark {
            width: 11px;
            height: 21px;
            path {
              fill: #ffffff;
            }
          }
          svg.c-mark {
            display: none;
            width: 10px;
            height: 10px;
            path {
              fill: rgba($color: #171d26, $alpha: 0.9);
            }
          }
          &:hover {
            border-width: 2px;
          }
          &:active {
            border-width: 3px;
          }
          &.expand {
            background-color: rgba($color: #fff, $alpha: 0.6);
            border: none;
            svg.q-mark {
              display: none;
            }
            svg.c-mark {
              display: block;
            }
            &:hover {
              opacity: 0.9;
            }
          }
        }
        .desc {
          opacity: 0;
          width: 88%;
          min-height: 330px;
          border-radius: 14px;
          background-color: #ffffff;
          padding: 26px 30px;
          transition: opacity 0.2s ease;
          p {
            font-family: TBCXMedium;
            font-size: 18px;
            color: #181a26;
          }
          span {
            display: flex;
            font-family: TBCXRegular;
            font-size: 14px;
            line-height: 1.43;
            color: #181a26;
            padding: 20px 0;
          }
        }
        &:hover {
          background-color: rgba($color: #181a26, $alpha: 0.1);
          button {
            opacity: 1;
          }
        }
        &.expanded {
          background-color: rgba($color: #181a26, $alpha: 0.9);
          button {
            opacity: 1;
            width: 34px;
            height: 34px;
            background-color: rgba($color: #fff, $alpha: 0.6);
            border: none;
            svg.q-mark {
              display: none;
            }
            svg.c-mark {
              display: block;
            }
            &:hover {
              opacity: 0.9;
            }
          }
          .desc {
            opacity: 1;
          }
        }
      }
    }
    .answers {
      flex-wrap: wrap;
      button em {
        padding: 0 32px 0 14px;
      }
    }
  }
  .progress-bar-v1,
  .progress-bar-v2 {
    p {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 25px;
      position: absolute;
      bottom: 100%;
      left: 0;
      margin-bottom: 2px;
      font-size: 18px;
      font-family: TBCXBold;
      letter-spacing: 1.29px;
      color: #181a26;
      transition: min-width 0.2s ease;
      span {
        font-family: TBCXBold;
        font-size: 12px;
        letter-spacing: 0.86px;
        color: #a5b4bd;
      }
    }
  }
  .progress-bar-v1 {
    position: relative;
    background-color: #f3f3f4;
    i {
      display: flex;
      height: 4px;
      background-color: #FD4100;
      transition: width 0.2s ease;
    }
  }

  .progress-bar-v2 {
    position: relative;
    z-index: 9;
    ul {
      z-index: 1;
      display: flex;
      align-items: flex-end;
      height: 4px;
      li {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        background-color: #f3f3f4;
        min-height: 4px;
        margin-right: 1px;
        &:last-child {
          margin-right: 0;
        }
        &.current {
          background-color: #637279;
        }
        i.placeholder {
          width: 100%;
          display: flex;
          height: 4px;
          transition: height 0.2s ease;
        }
        &.true,
        &.false {
          padding-top: 20px;
          background-color: transparent;
        }
        &.true:hover,
        &.false:hover {
          i.placeholder {
            height: 8px;
          }
        }
        &.true i.placeholder {
          background-color: #29b14e;
        }
        &.false i.placeholder {
          background-color: #ff5b3a;
        }
        .placeholder-view {
          display: flex;
          left: 0;
          position: absolute;
          flex-direction: column;
          z-index: 999999;
          width: 740px;
          bottom: 100%;
          box-shadow: 0 13px 50px 0 rgba(96, 94, 111, 0.2);
          background-color: #ffffff;
          padding: 20px 20px 11px 20px;
          border-radius: 14px;
          img {
            display: flex;
            width: 100%;
            border-radius: 14px;
          }
          span.question {
            display: flex;
            font-size: 13px;
            line-height: 1.23;
            text-align: center;
            color: #181a26;
            padding: 19px 30px 30px 30px;
          }
          .answers {
            display: flex;
            flex-wrap: wrap;
            button {
              display: flex;
              align-items: center;
              flex-basis: 50%;
              background-color: transparent;
              border: none;
              padding: 0;
              margin-bottom: 15px;
              span {
                display: flex;
                width: 36px;
                height: 36px;
                background-color: #a5b4bd;
                background-position: center center;
                background-repeat: no-repeat;
                font-family: TBCXBold;
                font-size: 16px;
                color: #ffffff;
                border-radius: 11px;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
              }
              em {
                display: flex;
                font-family: TBCXRegular;
                font-size: 11px;
                line-height: 1.43;
                color: rgba($color: #181a26, $alpha: 0.9);
                text-align: left;
                padding: 0 20px 0 10px;
                font-style: normal;
              }
              &.true,
              &.false {
                span {
                  text-indent: -9999px;
                }
              }
              &.true {
                span {
                  transform: scale(1.05);
                  transform-origin: center center;
                  border-radius: 12px;
                  box-shadow: 0 10px 30px 0 rgba(41, 177, 78, 0.3);
                  background-color: #29b14e !important;
                  background-size: 15px 10px;
                  background-image: url('../../svgs/done.svg');
                }
                em {
                  color: #29b14e !important;
                }
              }
              &.false {
                span {
                  box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
                  background-color: #ff5b3a !important;
                  background-size: 12px 12px;
                  background-image: url('../../svgs/error.svg');
                }
                em {
                  color: #ff5b3a !important;
                }
              }
            }
            &.answered {
              button {
                span {
                  background-color: rgba($color: #a5b4bd, $alpha: 0.4);
                }
                em {
                  color: rgba($color: #181a26, $alpha: 0.4);
                }
              }
            }
          }
          &.from-right {
            right: 0;
            left: auto;
            &::before {
              right: 30px;
            }
          }
          &::before {
            position: absolute;
            top: 100%;
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #ffffff;
          }
        }
        &:first-child .placeholder-view {
          left: 30px;
          &::before {
            left: 30px;
          }
        }
        &:last-child .placeholder-view {
          right: 30px;
          &::before {
            right: 30px;
          }
        }
      }
    }
  }
  .status-bar {
    max-width: 980px;
    width: 100%;
    padding: 18px 20px;
    margin: auto;
    display: flex;
    flex-shrink: 0;
    .left-side {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 16px;
        font-family: TBCXBold;
        font-size: 18px;
        &.true {
          color: #29b14e;
          background-color: rgba($color: #29b14e, $alpha: 0.1);
        }
        &.false {
          color: #ff5b3a;
          background-color: rgba($color: #ff5b3a, $alpha: 0.1);
        }
      }
      span {
        display: flex;
        font-family: TBCXRegular;
        font-size: 12px;
        line-height: 1.42;
        color: rgba($color: #181a26, $alpha: 0.5);
        padding: 0 40px 0 14px;
      }
    }
    .right-side {
      flex-basis: 50%;
      display: flex;
      justify-content: flex-end;
      ul {
        display: flex;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          margin-left: 12px;
          background-color: rgba($color: #a5b4bd, $alpha: 0.2);
          img {
            display: flex;
            width: 50%;
          }
          &.current {
            background-color: #FD4100;
          }
        }
      }
      .timer {
        display: flex;
        align-items: center;
        svg {
          width: 23px;
          height: 28px;
          path {
            fill: #181a26;
            fill-rule: nonzero;
            transition: fill 0.2s ease;
          }
        }
        p {
          font-family: 'Digital Display';
          font-size: 28px;
          color: #181a26;
          padding-left: 20px;
        }
        &.timer-low svg path {
          fill: #ff5b3a;
        }
      }
      .next-btn {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          color: rgba($color: #181a26, $alpha: 0.5);
          padding-right: 15px;
        }
        button {
          width: 46px;
          height: 46px;
          border-radius: 16px;
          box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
          background-color: #FD4100;
          border: none;
          transition: opacity 0.2s ease;
          &:hover {
            opacity: 0.8;
          }
          svg {
            width: 6px;
            height: 10px;
            opacity: 0.9;
            path {
              fill: rgba($color: #ffffff, $alpha: 0.9);
              fill-rule: evenodd;
            }
          }
        }
      }
    }
  }
}

.ticket-modal-view {
  max-width: 660px;
  padding: 0 !important;
  .ticket-modal-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-wrap {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 30px 0;
      border-radius: 30px 30px 0 0;
      background-color: #fafafa;
      img {
        max-width: 100%;
        object-fit: cover;
      }
    }
    p {
      font-family: TBCXMedium;
      font-size: 34px;
      padding: 40px 50px 9px 50px;
    }
    span {
      display: flex;
      font-family: TBCXRegular;
      font-size: 16px;
      line-height: 1.88;
      padding: 0 50px;
    }
  }
  .ticket-modal-indicator {
    display: flex;
    padding: 48px 50px 0 50px;
    .indicator-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 20px;
      border-radius: 16px;
      border: solid 1px #e4e4e4;
      background-color: rgba($color: #e4e4e4, $alpha: 0.05);
    }
    .i-wrap {
      display: flex;
      align-items: center;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 14px;
        background-repeat: no-repeat;
        background-position: center center;
        svg path {
          fill-rule: evenodd;
        }
      }
      .overflow {
        padding: 2px 0 0 18px;
        p {
          font-family: TBCXBold;
          font-size: 26px;
          line-height: 100%;
        }
        span {
          padding-top: 3px;
          display: flex;
          font-family: TBCXRegular;
          font-size: 11px;
          color: rgba($color: #181a26, $alpha: 0.5);
        }
      }
      &.i-true {
        i svg {
          width: 20px;
          height: 14px;
        }
        .overflow p {
          color: #29b14e;
        }
      }
      &.i-false {
        i svg {
          width: 14px;
          height: 14px;
        }
        .overflow p {
          color: #ff5b3a;
        }
      }
      &.i-clock {
        i {
          background-size: 20px 24px;
          background-image: url('../../svgs/timer.svg');
          background-color: rgba($color: #637279, $alpha: 0.1);
        }
        p {
          font-family: 'Digital Display';
          font-size: 26px;
          color: #181a26;
        }
      }
    }
  }
  .ticket-modal-actions {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      font-family: TBCXMedium;
      border-radius: 16px;
      border: none;
      padding: 0 20px;
      text-align: center;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
      &:first-child {
        width: 30%;
        background-color: rgba($color: #637279, $alpha: 0.1);
        color: #181a26;
        letter-spacing: 0.4px;
        font-size: 14px;
      }
      &:last-child {
        width: 65%;
        box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
        background-color: #FD4100;
        color: #ffffff;
        font-size: 18px;
      }
    }
  }
}

.ticket-modal-view.ticket-modal-winner {
  .ticket-modal-overview {
    img {
      height: 196px;
    }
    p {
      color: #29b14e;
    }
    span {
      color: #181a26;
    }
  }
  .ticket-modal-indicator {
    .i-wrap {
      &.i-true {
        i {
          svg path {
            fill: #fff;
          }
          box-shadow: 0 9px 24px 0 rgba(41, 177, 78, 0.2);
          background-color: #29b14e;
        }
      }
      &.i-false {
        i {
          svg path {
            fill: #ff5b3a;
          }
          background-color: rgba($color: #ff5b3a, $alpha: 0.1);
        }
      }
    }
  }
}

.ticket-modal-view.ticket-modal-failed {
  .ticket-modal-overview {
    img {
      height: 165px;
    }
    p {
      color: #181a26;
    }
    span {
      color: rgba($color: #181a26, $alpha: 0.7);
    }
  }
  .ticket-modal-indicator {
    .i-wrap {
      &.i-true {
        i {
          svg path {
            fill: #29b14e;
          }
          background-color: rgba($color: #29b14e, $alpha: 0.1);
        }
      }
      &.i-false {
        i {
          svg path {
            fill: #ffffff;
          }
          box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
          background-color: #ff5b3a;
        }
      }
    }
  }
}

.ticket-modal-view.ticket-modal-complete {
  .ticket-modal-overview {
    img {
      height: 165px;
    }
    p {
      color: #181a26;
    }
    span {
      color: rgba($color: #181a26, $alpha: 0.7);
    }
  }
  .ticket-modal-indicator {
    .indicator-wrap {
      justify-content: space-around;
    }
    .i-wrap {
      &.i-true {
        i {
          svg path {
            fill: #fff;
          }
          box-shadow: 0 9px 24px 0 rgba(41, 177, 78, 0.2);
          background-color: #29b14e;
        }
      }
      &.i-false {
        i {
          svg path {
            fill: #ffffff;
          }
          box-shadow: 0 9px 24px 0 rgba(255, 91, 58, 0.2);
          background-color: #ff5b3a;
        }
      }
    }
  }
}

.ticket-quest-modal-view {
  @include ticket-AQ;
  padding: 40px 40px 33px 40px !important;
  width: 620px !important;
  .question {
    padding: 0 0 19px 0;
    .cover {
      height: 250px;
    }
    p.caption {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.3px;
      padding-top: 18px;
    }
  }
  .answers {
    flex-direction: column;
    padding: 0 10px;
    button em {
      padding: 0 24px;
    }
  }
}

@media all and (max-height: 1080px) and (min-height: 840px) and (min-width: 980px) {
  .tickets-exam .ticket .question {
    padding: 90px 0 47px 0;
  }
}

@media all and (max-height: 1080px) and (min-width: 980px) {
  .tickets .ticket .question {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tickets .ticket .question .cover {
    width: 77%;
    height: 334px;
  }
  .tickets .ticket .question .q-desc-wrap button {
    width: 35px;
    height: 35px;
  }
  .tickets .ticket .question .q-desc-wrap button svg.q-mark {
    width: 9px;
    height: 21px;
  }
  .tickets .ticket .question .q-desc-wrap.expanded button {
    width: 30px;
    height: 30px;
  }
  .tickets .ticket .question .q-desc-wrap button svg.c-mark {
    width: 8px;
    height: 8px;
  }
  .tickets .ticket .question .q-desc-wrap .desc {
    width: 85%;
    min-height: auto;
    height: 100%;
    max-height: 290px;
    overflow-y: auto;
  }
  .tickets .ticket .question .q-desc-wrap .desc p {
    font-size: 17px;
  }
  .tickets .ticket .question .q-desc-wrap .desc span {
    font-size: 13px;
    padding: 15px 0 0 0;
  }
  .tickets .ticket .question p.caption {
    font-size: 14px;
  }
  .tickets .ticket .answers button span {
    width: 40px;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
  }
  .tickets .ticket .answers button em {
    font-size: 13px;
  }
}

@media all and (max-height: 900px) and (min-width: 980px) {
  .tickets .ticket .question .cover {
    width: 67%;
    height: 290px;
  }
  .tickets .ticket .question .q-desc-wrap .desc {
    max-height: 250px;
    padding: 20px 20px;
  }
  .tickets .ticket .question .q-desc-wrap.expanded button {
    width: 25px;
    height: 25px;
  }
  .tickets .ticket .question .q-desc-wrap button svg.c-mark {
    width: 6px;
    height: 6px;
  }
}

@media all and (max-height: 840px) and (min-width: 980px) {
  .tickets .ticket .question {
    padding: 0 0 30px 0;
  }
  .tickets-exam .ticket .question {
    padding: 80px 0 47px 0;
  }
  .tickets .ticket .question .cover {
    width: 55%;
    height: 238px;
  }
  .tickets .ticket .question .q-desc-wrap .desc {
    max-height: 195px;
    padding: 19px 15px;
  }
  .tickets .ticket .question .q-desc-wrap .desc p {
    font-size: 15px;
  }
  .tickets .ticket .question .q-desc-wrap .desc span {
    font-size: 12px;
    padding: 10px 0 0 0;
  }
  .tickets .ticket .question .q-desc-wrap.expanded button {
    top: 9px;
    right: 9px;
    width: 20px;
    height: 20px;
  }
}

@media all and (max-width: 980px), (max-height: 900px) {
  .tickets .status-bar {
    padding: 10px 20px;
  }
  .tickets .status-bar .left-side p {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    font-size: 14px;
  }
  .tickets .status-bar .left-side span {
    font-size: 11px;
  }
  .tickets .status-bar .right-side ul li {
    width: 35px;
    height: 35px;
  }
  .tickets .progress-bar-v1 p,
  .tickets .progress-bar-v2 p {
    font-size: 14px;
    padding: 0 20px;
  }
  .tickets.tickets-exam {
    justify-content: center;
  }
  .tickets .status-bar .right-side .timer p {
    font-size: 20px;
  }
  .tickets .status-bar .right-side .timer svg {
    width: 18px;
    height: 21px;
  }
  .tickets .status-bar .right-side .next-btn p {
    font-size: 11px;
  }
  .tickets .status-bar .right-side .next-btn button {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    svg {
      width: 4.19px;
      height: 7px;
    }
  }
}

@media all and (min-width: 980px) {
  .tickets .ticket .answers button:hover {
    opacity: 0.8;
  }
}

@media all and (max-width: 980px) {
  @mixin ticket-AQ {
    .question {
      .cover {
        border-radius: vw980(14px);
        img {
          border-radius: vw980(14px);
        }
      }
    }
    .answers {
      button {
        margin: vw980(15px) 0;
        span {
          width: vw980(50px);
          height: vw980(50px);
          border-radius: vw980(16px);
        }
        &.true {
          span {
            border-radius: vw980(18px);
            box-shadow: 0 vw980(10px) vw980(30px) 0 rgba(41, 177, 78, 0.3);
            background-size: vw980(20px) vw980(14px);
          }
          em {
            padding: 0 vw980(32px) 0 vw980(18px);
          }
        }
        &.false {
          span {
            box-shadow: 0 vw980(9px) vw980(24px) 0 rgba(255, 91, 58, 0.2);
            background-size: vw980(14px) vw980(14px);
          }
        }
      }
    }
  }
  .tickets-exam .ticket .question {
    padding-top: 100px !important;
  }
  .tickets {
    .ticket {
      @include ticket-AQ;

      .question {
        padding: 0 0 vw980(47px) 0;
        .cover {
          height: vw980(434px);
        }
        p.caption {
          padding-top: vw980(28px);
        }
        .q-desc-wrap {
          button {
            top: vw980(15px);
            right: vw980(15px);
            width: vw980(43px);
            height: vw980(43px);
            border: vw980(3px) solid #ffffff;
            svg.q-mark {
              width: vw980(11px);
              height: vw980(21px);
            }
            svg.c-mark {
              width: vw980(10px);
              height: vw980(10px);
            }
            &:hover {
              border-width: vw980(2px);
            }
            &:active {
              border-width: vw980(3px);
            }
          }
          .desc {
            width: vw980(830px);
            border-radius: vw980(14px);
            padding: vw980(26px) vw980(30px);
            min-height: 75%;
            max-height: 75%;
            overflow: auto;
            span {
              padding: vw980(20px) 0;
            }
          }
          &.expanded {
            button {
              width: vw980(34px);
              height: vw980(34px);
            }
          }
        }
      }
      .answers {
        button em {
          padding: 0 vw980(32px) 0 vw980(14px);
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  .tickets .progress-bar-v2 ul li {
    pointer-events: none;
  }
  .tickets .progress-bar-v2 ul li .placeholder-view {
    display: none;
  }
}

@media all and (max-width: 767px) {
  @mixin ticket-AQ {
    .answers {
      flex-direction: column;
      flex-wrap: nowrap;
      button {
        margin: vw980(20px) 0;
        flex-basis: auto;
        span {
          width: 40px;
          height: 40px;
          font-size: 20px;
        }
        em {
          font-size: 13px;
          padding: 0 0 0 vw980(14px);
        }
      }
    }
  }
  .tickets .ticket {
    @include ticket-AQ;
    .question {
      p.caption {
        font-size: 14px;
      }
      .q-desc-wrap {
        .desc {
          p {
            font-size: 16px;
          }
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  @mixin ticket-AQ {
    .answers {
      button {
        span {
          width: 35px;
          height: 35px;
          font-size: 17px;
        }
        em {
          font-size: 12px;
        }
      }
    }
  }
  .tickets .ticket {
    @include ticket-AQ;
    .question {
      p.caption {
        font-size: 14px;
      }
      .q-desc-wrap {
        .desc {
          p {
            font-size: 14px;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 660px) {
  .modal-manager .modal-manager-container .modal-manager-view {
    border-radius: 0;
  }
  .ticket-modal-view .ticket-modal-actions {
    padding: 50px 25px;
  }
  .ticket-modal-view .ticket-modal-indicator {
    padding: 48px 25px 0 25px;
  }
  .ticket-modal-view .ticket-modal-overview p {
    padding: 40px 25px 9px 25px;
  }
  .ticket-modal-view.ticket-modal-failed .ticket-modal-overview img {
    width: 55%;
    height: auto;
  }
}
@media all and (max-width: 600px) {
  .ticket-modal-view .ticket-modal-overview p {
    padding: 20px 25px 5px 25px;
    font-size: 30px;
  }
  .ticket-modal-view .ticket-modal-indicator .indicator-wrap {
    flex-direction: column;
  }
  .ticket-modal-view .ticket-modal-indicator .i-wrap {
    margin: 10px 0;
  }
  .ticket-modal-view .ticket-modal-overview span {
    font-size: 14px;
    padding: 0 20px;
  }
  .ticket-modal-view .ticket-modal-indicator {
    padding: 20px 25px 0 25px;
  }
}

@media all and (max-width: 550px) {
  .tickets .status-bar .right-side {
    display: none;
  }
  .tickets .status-bar .left-side {
    flex-basis: 100%;
    justify-content: center;
  }
  .tickets.tickets-exam {
    .status-bar .right-side {
      display: flex;
    }
  }
  .tickets.tickets-exam .status-bar .left-side {
    flex-basis: 50%;
    justify-content: flex-start;
  }
  .tickets .status-bar .right-side .next-btn p {
    display: none;
  }
}

@media all and (max-width: 500px) {
  @mixin ticket-AQ {
    .answers {
      button {
        span {
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
        em {
          // font-size: 12px;
          font-size: 14px;
        }
      }
    }
  }
  .tickets .ticket {
    @include ticket-AQ;
    .question {
      p.caption {
        // font-size: 12px;
      }
      .q-desc-wrap {
        .desc {
          p {
            font-size: 13px;
          }
          span {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 450px) {
  @mixin ticket-AQ {
    .answers {
      button {
        span {
          width: 25px;
          height: 25px;
          font-size: 12px;
        }
        em {
          font-size: 10px;
          padding: 0 0 0 10px;
        }
      }
    }
  }
  .tickets .ticket {
    @include ticket-AQ;
    .question {
      p.caption {
        font-size: 11px;
      }
      .q-desc-wrap {
        .desc {
          p {
            font-size: 12px;
          }
          span {
            font-size: 9.5px;
          }
        }
      }
    }
  }
  .tickets.tickets-exam .status-bar .right-side .timer svg {
    display: none;
  }
  .tickets.tickets-exam .status-bar .left-side span {
    display: none;
  }
  .tickets.tickets-exam .status-bar .left-side p {
    margin: 0 20px;
  }
  .ticket-modal-view .ticket-modal-actions {
    flex-direction: column;
    padding: 20px 25px;
  }
  .ticket-modal-view .ticket-modal-actions button:last-child,
  .ticket-modal-view .ticket-modal-actions a:last-child,
  .ticket-modal-view .ticket-modal-actions button:first-child,
  .ticket-modal-view .ticket-modal-actions a:first-child {
    width: 100%;
    font-size: 14px;
  }
  .ticket-modal-view .ticket-modal-actions button:first-child,
  .ticket-modal-view .ticket-modal-actions a:first-child {
    margin-bottom: 15px;
  }
  .ticket-modal-view .ticket-modal-actions button,
  .ticket-modal-view .ticket-modal-actions a {
    height: 60px;
    font-size: 14px;
  }
  .ticket-modal-view .ticket-modal-overview p {
    font-size: 24px;
    padding: 10px 20px 0 20px;
  }
  .ticket-modal-view .ticket-modal-overview span {
    font-size: 12px;
  }
}
