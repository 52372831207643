.categories-v2 {
  display: flex;
  .category-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 86px 0;
    .item {
      display: flex;
      flex-direction: column;
      padding-bottom: 200px;
      .overflow {
        display: flex;
        width: 100%;
      }
      .block,
      .empty,
      .slider-block {
        padding-left: 60px;
        display: flex;
        width: 50%;
      }
      .block {
        flex-shrink: 0;
        flex-grow: 1;
        position: relative;
        &.block-top .wrapt .shift {
          padding-right: 0;
        }
        .wrapt {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          .shift {
            width: 801px;
            padding-right: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .head {
              display: flex;
              align-items: center;
              padding-bottom: 47px;
              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90px;
                height: 90px;
                flex-shrink: 0;
                border-radius: 28px;
                box-shadow: 0 18px 40px -12px rgba(255, 146, 32, 0.35);
                background-color: #FD4100;
                font-family: TBCXMedium;
                font-size: 40px;
                color: #ffffff;
                font-style: normal;
              }
              p {
                font-family: TBCXMedium;
                font-size: 40px;
                line-height: 1.2;
                padding-left: 30px;
                span:first-child {
                  color: #181a26;
                }
                span:last-child:not(:only-child) {
                  color: #FD4100;
                }
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              padding: 35px 0;
              p {
                font-family: TBCXRegular;
                font-size: 16px;
                line-height: 1.5;
                color: #181a26;
              }
            }
            .ticket-count {
              display: flex;
              align-items: center;
              margin: 50px 0 0 0;
              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                border-radius: 22px;
                background-color: rgba($color: #FD4100, $alpha: 0.1);
              }
              span {
                font-family: TBCXMediumItalic;
                font-size: 14px;
                letter-spacing: 1px;
                color: #FD4100;
                padding-left: 24px;
              }
            }
          }
        }
      }
      .slider-block {
        display: flex;
        .slider {
          display: flex;
          align-items: center;
          width: 100%;
          height: 480px;
          overflow: hidden;
          background-color: rgba($color: #f8f7f6, $alpha: 0.6);
          .slick-list {
            width: 100%;
            height: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
          }
          .slide {
            outline: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .slick-prev,
          .slick-next {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: 60px;
            height: 60px;
            background-color: $white;
            border-radius: 100%;
            box-shadow: 10px 10px 34px 0 rgba(96, 94, 111, 0.08);
            transition: all 0.3s;
            &:hover {
              background: $orange;
              color: $white;
              box-shadow: 0 9px 24px 0 rgba(255, 146, 32, 0.2);
            }
            &.slick-disabled {
              display: none !important;
            }
          }
          .slick-prev:before,
          .slick-next:before {
            content: '';
            font-family: 'slick';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            width: 6px;
            height: 10px;
            opacity: 1;
          }
          .slick-prev:before {
            background-image: url(../../svgs/nav-prev.svg);
          }

          .slick-next:before {
            background-image: url(../../svgs/nav-next.svg);
          }
          .slick-prev {
            left: 50px;
            &:hover:before {
              background-image: url(../../svgs/nav-prev-hover.svg);
            }
          }
          .slick-next {
            right: 50px;
            &:hover:before {
              background-image: url(../../svgs/nav-next-hover.svg);
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1600px) {
  .categories-v2 .category-list .item .block {
    padding-left: 20px;
  }
  .categories-v2
    .category-list
    .item
    .block
    .wrapt
    .shift
    .info
    .info-item
    .block-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .categories-v2
    .category-list
    .item
    .block
    .wrapt
    .shift
    .info
    .info-item
    .block-wrap
    .title {
    width: 100%;
    padding: 0 0 5px 0;
  }
}

@media all and (max-width: 1500px) {
  .categories-v2 .category-list .item .block-top {
    width: 100%;
    .wrapt .shift {
      width: 100%;
    }
    .wrapt .shift .head p {
      display: flex;
      flex-direction: column;
    }
  }
  .categories-v2 .category-list .item .slider-block {
    padding-left: 0;
  }
  .categories-v2 .category-list .item .slider-block .slider {
    background-color: transparent;
  }
}

@media all and (max-width: 1300px) {
  .categories-v2 .category-list .item .overflow {
    flex-direction: column;
  }
  .categories-v2 .category-list .item .block {
    width: 100%;
    padding: 0 20px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift {
    width: 100%;
    padding: 0;
  }
  .categories-v2 .category-list .item .slider-block {
    width: 80%;
    margin: auto;
    padding-top: 100px;
  }
  .categories-v2 .category-list .item .slider-block .slider {
    height: 500px;
  }
}

@media all and (max-width: 1000px) {
  .categories-v2 .category-list .item .block-top {
    .wrapt .shift .head p {
      display: block;
    }
  }
}

@media all and (max-width: 800px) {
  .categories-v2 .category-list .item .slider-block {
    width: 100%;
  }
  .categories-v2 .category-list .item .slider-block .slider {
    border-radius: 0;
  }
  .categories-v2 .category-list .item .slider-block .slider .slide img {
    height: 400px;
  }
}

@media all and (max-width: 600px) {
  .categories-v2 .category-list .item .block .wrapt .shift .head i {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    font-size: 40px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .head p {
    font-size: 26px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .icon {
    display: flex;
    align-items: center;
    padding: 25px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 15px;
    padding: 25px 0 18px 0;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .info .info-item {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .info .info-item .icon {
    justify-content: flex-start;
  }
  .categories-v2
    .category-list
    .item
    .block
    .wrapt
    .shift
    .info
    .info-item
    .block-wrap {
    padding: 32px 0;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .info .info-item .icon {
    width: 100%;
    padding-right: 0;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .ticket-count {
    margin: 50px auto 0 auto;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .info p {
    font-size: 14px;
    line-height: 1.7;
  }
  .categories-v2 .category-list .item {
    padding-bottom: 100px;
  }
}

@media all and (max-width: 500px) {
  .categories-v2 .category-list .item .block .wrapt .shift .head i {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    font-size: 30px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .head p {
    font-size: 20px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .icon {
    padding: 15px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 10px;
    padding: 18px 0;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text p {
    font-size: 14px;
    line-height: 1.3;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text span {
    font-size: 13px;
    line-height: 1.3;
  }
  .categories-v2 .category-list .item .slider-block .slider {
    height: 400px;
  }
  .categories-v2 .category-list .item .slider-block .slider .slide img {
    height: 300px;
  }
}

@media all and (max-width: 500px) {
  .categories-v2 .category-list .item .block .wrapt .shift .head {
    padding-bottom: 30px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .head i {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    font-size: 26px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .head p {
    font-size: 18px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .icon {
    padding: 10px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text {
    margin: 0 5px;
    padding: 15px 0;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text p {
    font-size: 13px;
  }
  .categories-v2 .category-list .item .block .wrapt .shift .rules .rule .text span {
    font-size: 12px;
    line-height: 1.6;
  }
  .categories-v2 .category-list .item .slider-block .slider {
    height: 350px;
  }
  .categories-v2 .category-list .item .slider-block .slider .slide img {
    height: 250px;
  }
  .categories-v2 .category-list .item .slider-block .slider .slick-prev,
  .categories-v2 .category-list .item .slider-block .slider .slick-next {
    width: 40px;
    height: 40px;
  }
  .categories-v2 .category-list .item .slider-block .slider .slick-prev {
    left: 25px;
  }
  .categories-v2 .category-list .item .slider-block .slider .slick-next {
    right: 25px;
  }
}
