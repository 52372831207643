@font-face {
  font-family: 'TBCXLight';
  src: url('../../fonts/TBCXLight/TBCXLight.eot');
  src: url('../../fonts/TBCXLight/TBCXLight.eot') format('embedded-opentype'),
  url('../../fonts/TBCXLight/TBCXLight.woff2') format('woff2'),
  url('../../fonts/TBCXLight/TBCXLight.woff') format('woff'),
  url('../../fonts/TBCXLight/TBCXLight.ttf') format('truetype');
}

@font-face {
  font-family: 'TBCXRegular';
  src: url('../../fonts/TBCXRegular/TBCXRegular.eot');
  src: url('../../fonts/TBCXRegular/TBCXRegular.eot') format('embedded-opentype'),
  url('../../fonts/TBCXRegular/TBCXRegular.woff2') format('woff2'),
  url('../../fonts/TBCXRegular/TBCXRegular.woff') format('woff'),
  url('../../fonts/TBCXRegular/TBCXRegular.ttf') format('truetype');
}
@font-face {
  font-family: 'TBCXMedium';
  src: url('../../fonts/TBCXMedium/TBCXMedium.eot');
  src: url('../../fonts/TBCXMedium/TBCXMedium.eot') format('embedded-opentype'),
  url('../../fonts/TBCXMedium/TBCXMedium.woff2') format('woff2'),
  url('../../fonts/TBCXMedium/TBCXMedium.woff') format('woff'),
  url('../../fonts/TBCXMedium/TBCXMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'TBCXBold';
  src: url('../../fonts/TBCXBold/TBCXBold.eot');
  src: url('../../fonts/TBCXBold/TBCXBold.eot') format('embedded-opentype'),
  url('../../fonts/TBCXBold/TBCXBold.woff2') format('woff2'),
  url('../../fonts/TBCXBold/TBCXBold.woff') format('woff'),
  url('../../fonts/TBCXBold/TBCXBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TBCXBlack';
  src: url('../../fonts/TBCXBlack/TBCXBlack.eot');
  src: url('../../fonts/TBCXBlack/TBCXBlack.eot') format('embedded-opentype'),
  url('../../fonts/TBCXBlack/TBCXBlack.woff2') format('woff2'),
  url('../../fonts/TBCXBlack/TBCXBlack.woff') format('woff'),
  url('../../fonts/TBCXBlack/TBCXBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'Digital Display';
  src: url('../../fonts/Digital-Display/Digital-Display.eot');
  src: url('../../fonts/Digital-Display/Digital-Display.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Digital-Display/Digital-Display.woff2') format('woff2'),
  url('../../fonts/Digital-Display/Digital-Display.woff') format('woff'),
  url('../../fonts/Digital-Display/Digital-Display.ttf') format('truetype'),
  url('../../fonts/Digital-Display/Digital-Display.svg#Digital-Display') format('svg');
  font-weight: normal;
  font-style: normal;
}

